import React, {useEffect, useState, useRef} from "react";
import {useConnect} from "../../../../../api/contracts";
import {ETHPriceDecimals, MaxUint256, SBDDecimals, SRTCoinDecimal} from "../../../../../config/constants";
import {Button, Form, Input, Select, Pagination, Empty, notification, message} from "antd";
import {dealMethod, dealPayMethod, viewMethod} from "../../../../../utils/contractUtil";
import addressMap from "../../../../../api/addressMap";
import {getContractByName, getContractByContract} from "../../../../../api/connectContract";
import judgeStatus from "../../../../../utils/judgeStatus";
import {getMiningRecord, getWithdrawSrtRecord} from '../../../../../graph/NFTLockGQL'
import BigNumber from "bignumber.js";
import {formatAddress} from "../../../../../utils/publicJs";
import {useLocation, useNavigate} from "react-router-dom";
import NFTLockStyle from './NFTLockStyle'
import {dealTime} from "../../../../../utils/timeUtil";
import {showNum} from "../../../../../utils/bigNumberUtil";
import {useTranslation} from "react-i18next";


const NFTLockV1 = () => {


    let {state, dispatch} = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [intervalTime, setIntervalTime] = useState();

    const [nodeLock, setNodeLock] = useState()
    const [withdrawLock, setWithdrawLock] = useState()
    const [curId, setCurId] = useState()
    const [withId, setWithId] = useState()

    const [totalNFT, setTotalNFT] = useState([]);


    const [indexArr, setIndexArr] = useState([]);
    const [myIdArr, setmyIdArr] = useState([]);
    const [chooseWithdrawIdArr, setChooseWithdrawIdArr] = useState([]);

    const [IdArr, setIdArr] = useState([])


    const [curIndex, setCurIndex] = useState(0);

    const [sbdRewards, setSbdRewards] = useState([])
    const [sbdRewardsNum, setSbdRewardsNum] = useState([])


    const [pendingSrt, setPendingSrt] = useState()
    const [totalReword, setTotalRewordSrt] = useState()

    const [srtBalance, setSrtBalance] = useState();
    const [poolSRTBalance, setPoolSrtBalance] = useState(0);

    const [smallNft, setSmallNftAmount] = useState();
    const [bigNft, setBigNftAmount] = useState();
    const [supNft, setSupNftAmount] = useState();
    const [lockAmouns, setLockAmouns] = useState();

    const [adminAddr, setAdmin] = useState("");
    const [isApprove, setIsApprove] = useState(false);

    const [smallNode, setSmallNode] = useState();
    const [bigNode, setBigNode] = useState();
    const [superNode, setSuperNode] = useState();

    const [useSmallNft, setUseSmallNftLock] = useState();
    const [useBigNft, setUseBigNftLock] = useState();
    const [useSupNft, setUseSupNftLock] = useState();

    const [totalPower, setTotalPower] = useState();

    const [smallPower, setSmallPower] = useState();
    const [bigPower, setBigPower] = useState();
    const [supPower, setSupPower] = useState();

    const [userPower, setUserPower] = useState();
    const [nftPower, setNftPower] = useState();


    const [miningRecord, setMiningRecord] = useState([]);
    const [unMiningRecord, setUnMiningRecord] = useState([]);
    const [withSrtRecord, setWithSrtRecord] = useState([]);

    const [curPage, setCurPage] = useState(1);
    const [activeNav, setActiveNav] = useState(0);
    const [form] = Form.useForm();
    const history = useNavigate()
    const { t } = useTranslation();
    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const goPage = (url) => {
        history(url)
    }

    const nodeList = [
        {
            value: smallNode,
            label: 'Small Node V1',
        },
        {
            value: bigNode,
            label: 'Big Node V1',
        },
        {
            value: superNode,
            label: 'Super Node V1',
        },

    ]


    const onChangePage = async (page) => {
        await setCurPage(page)
        getwithdrawSrtRecord(curPage, page)
        getminingRecord(curPage, page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        getwithdrawSrtRecord(count, curPage)
        getminingRecord(count, curPage)
    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const getAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setAdmin(admin)
    }
    const getAllowance = async () => {
        "erc721/isApprovedForAll"
        let contractTemp, addressNFT
        //授权需要使用erc721中setApprovalForAll是给一类节点下所有的nft授权
        if (nodeLock == smallNode) {
            contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
        }
        if (nodeLock == bigNode) {
            contractTemp = await getContractByContract("bignodeV1", bigNode, state.api,)
        }
        if (nodeLock == superNode) {
            contractTemp = await getContractByContract("supnodeV1", superNode, state.api,)
        }

        const isApprove = await viewMethod(contractTemp, state.account, "isApprovedForAll", [state.account, addressMap["NFTLock"].address])
        setIsApprove(isApprove)
    }
    useEffect(() => {
        if (nodeLock) {
            getAllowance()
        }
    }, [nodeLock])
    const handleApprove = async (name, params) => {
        let contractTemp, addressNFT
        //授权需要使用erc721中setApprovalForAll是给一类节点下所有的nft授权
        if (nodeLock == smallNode) {
            contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
            addressNFT = smallNode
        }
        if (nodeLock == bigNode) {
            contractTemp = await getContractByContract("bignodeV1", bigNode, state.api,)
            addressNFT = bigNode
        }
        if (nodeLock == superNode) {
            contractTemp = await getContractByContract("supnodeV1", superNode, state.api,)
            addressNFT = superNode
        }

        await dealMethod(contractTemp, state.account, "setApprovalForAll", [addressMap["NFTLock"].address, true])
        getAllowance()

    }


    const getAmountsId = async (curVal) => {
        let amountIdArr = []
        totalNFT.map((item) => {
            if (curVal == item.type) {
                amountIdArr.push({
                    label: "#" + item.id,
                    value: item.id
                });
            }

        })
        setIdArr(amountIdArr)

    }
    const getInitDay = async () => {
        let lockBlockNumber = await handleViewMethod("lockBlockNumber", [])
        let intervalTime = await handleViewMethod("intervalTime", [])
        let day = await handleViewMethod("day", [])
        setIntervalTime(BigNumber(lockBlockNumber).multipliedBy(intervalTime).div(day).toFixed(0, BigNumber.ROUND_CEIL))
    }

    const getNodeLock = async (value) => {
        console.log(value)
        setNodeLock(value)
        setCurId(undefined)
        getAmountsId(value);

    }
    const getWithdrawLock = async (value) => {
        setWithdrawLock(value)
        setWithId(undefined)
        getWithdrawId(value)

        if (value == '1') {
            let res = await handleViewMethod('userSmallNftAmount', [state.account])
            setLockAmouns(res)
        }
        if (value == '2') {
            let res = await handleViewMethod('userBigNftAmount', [state.account])
            setLockAmouns(res)
        }
        if (value == '3') {
            let res = await handleViewMethod('userSupNftAmount', [state.account])
            setLockAmouns(res)
        }

    }


    const getMyLockId = async () => {
        let length = await handleViewMethod('getUserLockLength', [state.account])
        let smallLockNum = 0, bigLockNum = 0, superLockNum = 0
        let valueArr = []
        for (let i = 0; i < length; i++) {
            let res = await handleViewMethod('lockInfos', [state.account, i])
            valueArr.push(res)
            if (res.nft.toString().toLowerCase() == smallNode.toString().toLowerCase()) {
                smallLockNum++
            }

            if (res.nft.toString().toLowerCase() == bigNode.toString().toLowerCase()) {
                bigLockNum++
            }

            if (res.nft.toString().toLowerCase() == superNode.toString().toLowerCase()) {
                superLockNum++
            }

        }
        setUseSmallNftLock(smallLockNum)
        setUseBigNftLock(bigLockNum)
        setUseSupNftLock(superLockNum)
        setmyIdArr(valueArr)
    }
    const getWithdrawId = async (curVal) => {
        const nftMap = [smallNode, bigNode, superNode]
        let tempArr = []
        myIdArr.map(item => {
            if (nftMap[curVal - 1] == item.nft) {
                tempArr.push({
                    label: '#' + item.tokenId,
                    value: item.tokenId
                })
            }
        })
        setChooseWithdrawIdArr(tempArr)
    }

    const getPendingSrt = async () => {
        let userTotalLock = await handleViewMethod('userTotalLock', [state.account])
        let res = await handleViewMethod('CanClaimSrt', [state.account])
        setPendingSrt(BigNumber(res).div(10 ** 18).toFixed(3))
        setTotalRewordSrt(BigNumber(userTotalLock).div(10 ** 18).toFixed(3))
    }


    const getLock = async () => {
        if (!curId && curId != 0) {
            message.error("Please choose nft")
            return
        }
        console.log(nodeLock, curId)
        await handleDealMethod('staking', [nodeLock, curId])
        getMyLockId()
        getMyNFT()
        setTimeout(() => {
            getminingRecord()
            getAmountsId()
        }, 500)
    }

    const getNftToReward = async (name, params) => {
        let smallPow = await handleViewMethod("nftToReward", [smallNode])
        let bigPow = await handleViewMethod("nftToReward", [bigNode])
        let superPow = await handleViewMethod("nftToReward", [superNode])
        setSmallPower(BigNumber(smallPow).div(10 ** SRTCoinDecimal).toString())
        setBigPower(BigNumber(bigPow).div(10 ** SRTCoinDecimal).toString())
        setSupPower(BigNumber(superPow).div(10 ** SRTCoinDecimal).toString())

    }

    const getWithdraw = async () => {
        await handleDealMethod('withdrawNft', [withdrawLock, withId])
        getWithdrawId()
        getMyNFT()

    }

    const userSRTBalance = async () => {
        let contracts = await getContractByName('SRT', state.api,)
        let res = await viewMethod(contracts, state.account, "balanceOf", [state.account])

        let poolRes = await viewMethod(contracts, state.account, "balanceOf", [addressMap["NFTLock"].address])
        setPoolSrtBalance(BigNumber(poolRes).div(10 ** SRTCoinDecimal).toString())
        setSrtBalance(BigNumber(res).div(10 ** SRTCoinDecimal).toString())
    }
    const getMyNFT = async () => {

        //生成合约实例
        let contractTempSmall = await getContractByName("smallnodeV1",  state.api,)
        let contractTempBig = await getContractByName("bignodeV1",  state.api,)
        let contractTempSuper = await getContractByName("supnodeV1", state.api,)
        //minted
        let smallMintedId = await viewMethod(contractTempSmall, state.account, "getUserMintIds", [state.account])
        let bigMintedId = await viewMethod(contractTempBig, state.account, "getUserMintIds", [state.account])
        let superMintedId = await viewMethod(contractTempSuper, state.account, "getUserMintIds", [state.account])
        // burned
        let smallBurnIds = await viewMethod(contractTempSmall, state.account, "getUserBurnIds", [state.account])
        let bigBurnIds = await viewMethod(contractTempBig, state.account, "getUserBurnIds", [state.account])
        let superBurnIds = await viewMethod(contractTempSuper, state.account, "getUserBurnIds", [state.account])

        let totalNFT = []

        for (let i of smallMintedId) {
            if (smallBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: smallNode,
                    id:i
                })
            }
        }

        for (let i of bigMintedId) {
            if (bigBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: bigNode,
                    id:i
                })
            }
        }
        for (let i of superMintedId) {
            if (superBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: superNode,
                    id:i
                })
            }
        }

        setTotalNFT(totalNFT)

    }

    const getminingRecord = async () => {
        let records = await getMiningRecord(state.account)
        let res = records.data.lockRecords
        setMiningRecord(res)
    }

    const getwithdrawSrtRecord = async () => {
        let records = await getWithdrawSrtRecord(pageCount, curPage, state.account)
        let res = records.data.claimSrtRecords

        setWithSrtRecord(res)
    }
    const getSmall = async () => {
        let nftSmall = await handleViewMethod('smallNode', [])
        setSmallNode(nftSmall)

    }

    const getBig = async () => {
        let nftBig = await handleViewMethod('bigNode', [])
        setBigNode(nftBig)

    }
    const getSuper = async () => {
        let nfftSup = await handleViewMethod('supNode', [])
        setSuperNode(nfftSup)
    }
    const getSrt = async () => {
        await handleDealMethod('Claim', [state.account])
        getPendingSrt()
        userSRTBalance()
        setTimeout(() => {
            getwithdrawSrtRecord()
        }, 500)

    }
    useEffect(() => {
        if (smallNode && bigNode && superNode) {
            getNftToReward()
            getMyLockId()
            getMyNFT()

        }
    }, [smallNode, bigNode, superNode, state.account])
    useEffect(() => {
        if (smallNode && bigNode && superNode && totalNFT.length > 0) {
            getNftToReward()
            getNodeLock(smallNode)
        }
    }, [smallNode, bigNode, superNode, totalNFT])


    useEffect(async () => {
        getwithdrawSrtRecord()
        setSmallNode(addressMap["smallnodeV1"].address)
        setBigNode(addressMap["bignodeV1"].address)
        setSuperNode(addressMap["supnodeV1"].address)

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getSmall()
        getBig()
        getSuper()

        getAdmin()
        getminingRecord()

        getInitDay()

        getPendingSrt()
        userSRTBalance()

    }, [state.account]);

    return (
        <NFTLockStyle>
            <div className="panel-box">
                <div className="panel-title">
                    {
                        adminAddr.toString().toLowerCase() == state.account.toLowerCase() &&
                        <div className="lockadmin" onClick={() => {
                            goPage('/NFTLockAdmin')
                        }}>
                            Admin
                        </div>
                    }
                </div>

                <div className="panel-container">
                    <div className="nft-box">
                        <div className="left-part nftlock">
                                <div className="nft-title">
                                    {t("Node V1 Lock Mining Pool")} (SRT)
                                </div>
                                <div className="nft-content">
                                    <p className="name">Super Node V1</p>
                                    <p className="value">{supPower}</p>
                                </div>
                                <div className="nft-content">
                                    <p className="name">Big Node V1</p>
                                    <p className="value">{bigPower}</p>
                                </div>
                                <div className="nft-content">
                                    <p className="name">Small Node V1</p>
                                    <p className="value">{smallPower}</p>
                                </div>
                                <div className="nft-content">
                                    <p className="name">{t("Unlocking Days")}</p>
                                    <p className="value">{intervalTime}</p>
                                </div>

                        </div>
                        <div className="nftlock right-part">
                            <div className="nft-title">
                                {t("My Node V1 Lock")}
                            </div>
                            <div className="nft-content">
                                <p className="name">Super Node V1 </p>
                                <p className="value">{useSupNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">Big Node V1 </p>
                                <p className="value">{useBigNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">Small Node V1 </p>
                                <p className="value">{useSmallNft}</p>
                            </div>

                        </div>
                    </div>
                    <div className="nav-box">
                        <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                            setActiveNav(0)
                        }}>
                            {t("Lock Mining")}
                        </div>

                        <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                            setActiveNav(2)
                        }}>
                            {t("Withdraw")} SRT
                        </div>
                    </div>
                    {activeNav == 0 && <div>
                        <div className="lock-box">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">{t("Your Lock NFT")}</p>
                                        <p className="value"> {t("Balance")}: {IdArr.length}</p>
                                    </div>
                                    <div className="small-nft">

                                        <div className="input-box">
                                            <Select

                                                value={nodeLock}
                                                onChange={(value) => {
                                                    getNodeLock(value)
                                                }}
                                                className='select-box'
                                                options={nodeList}
                                                style={{
                                                    width: "100%",
                                                    color: '#FFF'
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">{t("Node")} ID</p>
                                    </div>
                                    <div className="max-box">

                                        <Select
                                            value={curId}
                                            onChange={(value) => {
                                                setCurId(value)
                                            }}
                                            className='select-box'
                                            options={IdArr}
                                            style={{
                                                width: "100%",
                                                color: '#FFF'
                                            }}

                                        >

                                        </Select>

                                    </div>
                                    {!isApprove && <Button onClick={() => {
                                        handleApprove()
                                    }} type="primary" className="lock-btn">
                                        Approve
                                    </Button>}
                                    {isApprove && <Button onClick={() => {
                                        getLock()
                                    }} type="primary" className="lock-btn">
                                        {t("Lock Mining")}
                                    </Button>}

                                </div>
                            </Form>
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("NFT Lock Mining Records")}
                            </h1>

                            <div className="superdao-list-box nft-list">
                                <div className='list-headert'>
                                    <div className="list-header ">
                                        <div className="col">
                                            {t("No.")}
                                        </div>
                                        <div className="col">
                                           {t("Address")}
                                        </div>

                                        <div className="col">
                                            NFT
                                        </div>

                                        <div className="col">
                                            ID
                                        </div>
                                        {/*<div className="col">*/}
                                        {/*    Power*/}
                                        {/*</div>*/}
                                        <div className="col">
                                             {t("Time(UTC)")}
                                        </div>

                                    </div>

                                    {
                                        miningRecord.length == 0 ? <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}/> : miningRecord.map((item, index) => {
                                            return (
                                                <div className="list-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {item.nft}
                                                    </div>
                                                    <div className="col">
                                                        {item.tokenId}
                                                    </div>
                                                    {/*<div className="col">*/}
                                                    {/*    {item.power}*/}
                                                    {/*</div>*/}
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                            <div className="pagination">
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            </div>
                        </div>
                    </div>
                    }
                    {activeNav == 1 && <div>
                        <div className="lock-box">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">{t("Choose NFT")}</p>
                                        <p className="value">{t("Lock Amounts")} {lockAmouns}</p>
                                    </div>
                                    <div className="small-nft">
                                        <div className="input-box">
                                            <Select
                                                value={withdrawLock}
                                                onChange={(value) => {
                                                    getWithdrawLock(value)
                                                }}
                                                options={nodeList}
                                                style={{
                                                    width: "100%",
                                                    color: '#FFF'
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">{t("Node")} ID</p>
                                        {/* <p className="value">{ }</p> */}
                                    </div>
                                    <div className="max-box">
                                        <Select
                                            value={withId}
                                            onChange={(value) => {
                                                setWithId(value)
                                            }}
                                            className='select-box'
                                            options={chooseWithdrawIdArr}
                                            style={{
                                                width: "100%",
                                                color: '#FFF'
                                            }}

                                        >

                                        </Select>

                                    </div>
                                    <Button onClick={() => {
                                        getWithdraw()
                                    }} type="primary" className="lock-btn">
                                       {t("Withdraw")}
                                    </Button>

                                </div>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Withdraw NFT Records")}
                            </h1>

                            <div className="superdao-list-box withdrawnft-list">
                                <div className='list-headert3'>
                                    <div className="list-header withnft-records-header">
                                        <div className="col">
                                            {t("No.")}
                                        </div>

                                        <div className="col">
                                           {t("Address")}
                                        </div>
                                        <div className="col">
                                            NFT
                                        </div>
                                        <div className="col">
                                            ID
                                        </div>

                                        <div className="col">
                                             {t("Time(UTC)")}
                                        </div>

                                    </div>

                                    {unMiningRecord.length == 0 ? <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}/> : unMiningRecord.map((item, index) => {
                                        return (<div key={index} className="list-item withnft-records-item">
                                            <div className="col no">
                                                {index + 1}
                                            </div>
                                            <div className="col address">
                                                <a>
                                                    {formatAddress(item.user)}
                                                </a>
                                            </div>
                                            <div className="col">
                                                {item.nft}
                                            </div>
                                            <div className="col">
                                                {item.tokenId}
                                            </div>

                                            <div className="col">
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                    })}
                                </div>

                            </div>
                            <div className="pagination">
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            </div>

                        </div>
                    </div>
                    }
                    {activeNav == 2 && <div>
                        <div className="lock-box">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">{t("Your total lock")} SRT</p>
                                        <p className="value">{totalReword}</p>
                                    </div>
                                    <div className="nft-balance">
                                        <p className="name">{t("Your can withdraw")} SRT</p>
                                        <p className="value">{pendingSrt}</p>
                                    </div>

                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">{t("Amounts")}</p>
                                        <p className="value">{t("Balance")}: {showNum(srtBalance)}</p>
                                    </div>

                                    <Button onClick={() => {
                                        getSrt()
                                    }} type="primary" className="lock-btn">
                                       {t("Withdraw")}
                                    </Button>

                                </div>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Withdraw")} SRT {t("Records")}
                            </h1>

                            <div className="superdao-list-box withdraw-list">
                                <div className='list-headert2'>
                                    <div className="list-header withall-records-header">
                                        <div className="col">
                                            {t("No.")}
                                        </div>

                                        <div className="col">
                                           {t("Address")}
                                        </div>

                                        <div className="col">
                                             {t("Amounts")}
                                        </div>

                                        <div className="col">
                                             {t("Time(UTC)")}
                                        </div>

                                    </div>

                                    {withSrtRecord.length == 0 ? <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}/> : withSrtRecord.map((item, index) => {
                                        return (<div key={index} className="list-item withall-records-item">
                                            <div className="col no">
                                                {index + 1}
                                            </div>
                                            <div className="col address">
                                                <a>
                                                    {formatAddress(item.user)}
                                                </a>
                                            </div>
                                            <div className="col">
                                                {item.amount}
                                            </div>

                                            <div className="col">
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                    })}
                                </div>

                            </div>
                            <div className="pagination">
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            </div>

                        </div>
                    </div>
                    }

                </div>


            </div>
        </NFTLockStyle>
    )
}

export default NFTLockV1;