import {fetchQueryBase} from "./index";

// test05 new


let sptUrl = "patton-sr/e67d"

let bonus = "patton-sr/774e"

if (true) {
}

export function getBonusRecords(page, pageSize) {
    return fetchQueryBase(bonus, {
        text: `{
           bonusRecords(first: 1000) {
            operate
            _to
            _amount
            _threshold
            period
            bonusAmount
            blockTimestamp
            transactionHash
          }
        }`
    }, "")
}

export function getRecords(page, pageSize) {
    return fetchQueryBase(sptUrl, {
        text: `{
        records(first:1000  where:{DividendV2Id:2}){
            user
            amount
            blockTime
          }
        }`
    }, "")
}
