import React, { useEffect, useRef, useState } from 'react';
import { useConnect } from "../../../../api/contracts";
import { Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Pagination } from 'antd';
import { getContractByName, getContractByContract } from "../../../../api/connectContract";
import { dealMethod, viewMethod } from "../../../../utils/contractUtil"
import { useNavigate, useLocation } from "react-router-dom";
import SwapViewStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import { showNum } from "../../../../utils/bigNumberUtil";
import BigNumber from "bignumber.js";
import SRTIcon from "../../../../imgs/SRT.png";
import download from "../../../../imgs/download.png";
import SBDCoinIcon from "../../../../imgs/SBD.png";
import SVTCoinIcon from "../../../../imgs/svt.png";

import addressMap from "../../../../api/addressMap";
import { MaxUint256 } from "../../../../config/constants";
import { getAllSwapRecords, getSwapRecords } from "../../../../graph/swapGQL";
import develop from "../../../../env";
import { formatAddress } from "../../../../utils/publicJs";
import { dealTime } from "../../../../utils/timeUtil";
import AdminIcon from "../../../../imgs/user3.png"
import {useTranslation} from "react-i18next";
const SwapView = (props) => {
    let { state, dispatch } = useConnect();
    const [poolSBDCoinBalance, setPoolSBDCoinBalance] = useState(0)
    const [SRTCoinBalance, setSRTCoinBalance] = useState(0)

    const [salePrice, setSalePrice] = useState(0)
    const [srtBalance, setSrtBalance] = useState(0)
    const [sbdBalance, setSBDBalance] = useState(0)

    const [svtBalance, setSVTBalance] = useState(0)
    const [exchangeAmount, setExchangeAmount] = useState(0)
    const [activeNav, setActiveNav] = useState(0)
    const [srtDecimal, setSRTDecimal] = useState(18)
    const [sbdDecimal, setSBDDecimal] = useState(18)
    const [inputValue, setInputValue] = useState(undefined)
    const [owner, setOwner] = useState(" ")
    const [allowance, setAllowance] = useState(0)
    const [adminCoin, setAdminCoin] = useState({})
    const [claimRecord, setClaimRecord] = useState([])
    const [exchangeRecord, setExchangeRecord] = useState([])


    const [claimRecordAll, setClaimRecordAll] = useState([])
    const [exchangeRecordAll, setExchangeRecordAll] = useState([])
    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [addressValue, setAddressValue] = useState(undefined)

    const [lockInfo, setLockInfo] = useState()
    const [canWithdraw, setCanwithdraw] = useState()

    const [form] = Form.useForm();
    const history = useNavigate();
    const { t } = useTranslation();
    const location = useLocation()
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("Swap", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("Swap", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const getPoolBalance = async () => {

        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        setSRTDecimal(decimal)

        let contractTemp2 = await getContractByContract("erc20", addressMap["SBD"].address, state.api,)
        const decimal2 = await viewMethod(contractTemp2, state.account, "decimals", [])
        setSRTDecimal(decimal2)
        const balance = await getCoinInfo(addressMap["SBD"].address, addressMap["Swap"].address)
        const balance2 = await getCoinInfo(addressMap["SRT"].address, addressMap["Swap"].address)
        const allLock = await handleViewMethod("allLock", [])

        setPoolSBDCoinBalance(BigNumber(balance).minus(BigNumber(allLock).div(10 ** 18)).toString())
        setSRTCoinBalance(balance2)
        const mb = await getCoinInfo(addressMap["SBD"].address, state.account)
        const mb2 = await getCoinInfo(addressMap["SRT"].address, state.account)

        const svt = await getCoinInfo(addressMap["SVT"].address, state.account)

        setSBDBalance(mb)
        setSVTBalance(svt)
        setSrtBalance(mb2)
    }
    const getLockInfo = async () => {
        let lockInfo = await handleViewMethod("getUserLockAmount", [])
        const canClaim = await handleViewMethod("getClaimAmount", [])
        console.log(canClaim)
        setCanwithdraw(BigNumber(canClaim).div(10 ** sbdDecimal).toString())
        lockInfo = (BigNumber(lockInfo).div(10 ** sbdDecimal)).toString()
        setLockInfo(lockInfo)
    }
    const getAdmin = async () => {

        const owner = await handleViewMethod("owner", [])
        setOwner(owner)

    }
    const handleSwap = async () => {
        await handleDealMethod("exchange", [BigNumber(exchangeAmount).multipliedBy(10 ** srtDecimal).toFixed(0).toString()])
        getPoolBalance()
        getLockInfo()
    }
    const handleWithdraw = async () => {
        if (BigNumber(inputValue).lt(0.01)) {
            message.warn("Min claim 0.01", 5)
            return
        }
        await handleDealMethod("claim", [BigNumber(inputValue).multipliedBy(10 ** srtDecimal).toFixed(0).toString()])
        getPoolBalance()
        getLockInfo()
    }
    const handleApprove = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["Swap"].address, MaxUint256])
        getAllowance()
    }
    const getAllowance = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap["Swap"].address])
        setAllowance(res)
    }
    const getAdminCoinInfo = async (addressValue) => {
        if (!state.api.utils.isAddress(addressValue)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", addressValue, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        const name = await viewMethod(contractTemp, state.account, "name", [])
        const symbol = await viewMethod(contractTemp, state.account, "symbol", [])
        let balance = await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["Swap"].address])
        balance = BigNumber(balance).div(10 ** decimal).toString()
        setAdminCoin({
            balance,
            decimal,
            name,
            symbol
        })

    }
    const getCoinInfo = async (coinAddr, toAddr) => {
        if (!state.api.utils.isAddress(coinAddr)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", coinAddr, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        let balance = await viewMethod(contractTemp, toAddr, "balanceOf", [toAddr])
        balance = BigNumber(balance).div(10 ** decimal)
        return balance

    }
    const getData = async (page) => {
        getPoolBalance()
        getLockInfo()
        getAllowance()

        getAdmin()
    }


    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        const res = await getSwapRecords(state.account)
        if (res && res.data) {
            setExchangeRecord(res.data.exchanges)
            setClaimRecord(res.data.claims)
        }
        if (owner.toString().toLowerCase() == state.account.toLowerCase()) {
            const allRes = await getAllSwapRecords()

            if (allRes && allRes.data) {
                setExchangeRecordAll(allRes.data.exchanges)
                setClaimRecordAll(allRes.data.claims)
            }
        }
        await getData()

    }, [state.account]);
    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    return (
        <SwapViewStyle>
            <div className="panel-title">
                {activeNav == 0 && owner.toString().toLowerCase() == state.account.toLowerCase() &&
                    <img style={{cursor:"pointer",float:"right"}} onClick={()=>{history("/SwapAdmin")}} width={26} src={AdminIcon} alt=""/>
                }
            </div>
            <div className="header-box">

                <div className="header-item">
                    <div className="name">
                        {t("Pool SBD Amounts")}
                    </div>
                    <div className="value">
                        {BigNumber(poolSBDCoinBalance).toFixed(1)} SBD
                    </div>
                </div>
                {/*<div className="header-item">*/}
                {/*    <div className="name">*/}
                {/*        SRT Amounts*/}
                {/*    </div>*/}
                {/*    <div className="value">*/}
                {/*        {showNum(SRTCoinBalance)} SRT*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="nav-box">
                <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                    setActiveNav(0)
                }}>
                    {t("Swap")}
                </div>
                <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                    setActiveNav(1)
                }}>
                    {t("Withdraw")}
                </div>
            </div>
            {activeNav == 0 && <div className="operate-box">

                <Form form={form} name="control-hooks" className="form">
                    <div className="withdraw-part">
                        <div className="balance-box">

                            <strong>{t("Your Pay")}</strong>

                            <div className="right flex-box">
                                <div className="name">
                                    {t("Balance")}:
                                </div>
                                <div className="value">
                                    <span>
                                        {showNum(srtBalance)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Form.Item
                            name="amount"
                            validateTrigger="onBlur"
                            validateFirst={true}
                        >
                            <div className="input-box">
                                <Input placeholder="0" onChange={(e) => {
                                    setExchangeAmount(e.target.value)
                                }}></Input>
                                <div className="right-tip">
                                    <img className="coin-icon" src={SRTIcon} alt="" />
                                    SRT
                                </div>
                            </div>
                        </Form.Item>
                    </div>
                    <img className="down-icon" src={download} alt="" />


                    <div className="withdraw-part" style={{ marginTop: '8px' }}>
                        <div className="balance-box">
                            <strong className="">
                                {t("Your Receive")}SBD
                            </strong>
                            <div className="balance-box ">
                                <div className="name">
                                     {t("Balance")}:
                                </div>
                                <div className="value">
                                    <span>{showNum(sbdBalance)}</span>
                                </div>
                            </div>
                        </div>
                        <Form.Item
                            validateTrigger="onBlur"
                            validateFirst={true}

                        >
                            <div className="input-box">
                                <div className="exchangeAmount">
                                    {showNum(exchangeAmount * 0.2)}
                                </div>
                                <div className="right-tip">
                                    <img className="coin-icon" width={20} src={SBDCoinIcon} alt="" />
                                    SBD
                                </div>
                            </div>
                        </Form.Item>

                    </div>
                    <div className="withdraw-part" style={{ marginTop: '8px' }}>
                        <div className="balance-box">
                            <strong className="">
                                {t("Your Lock SBD")}
                            </strong>
                            <div className="balance-box ">

                            </div>
                        </div>
                        <Form.Item
                            validateTrigger="onBlur"
                            validateFirst={true}
                        >
                            <div className="input-box">
                                <div className="exchangeAmount">
                                    {showNum(exchangeAmount * 0.8)}
                                </div>
                                <div className="right-tip">
                                    <img className="coin-icon" width={20} src={SBDCoinIcon} alt="" />
                                    SBD
                                </div>
                            </div>
                        </Form.Item>


                    </div>
                    <div className="withdraw-part" style={{ marginTop: '8px' }}>
                        <div className="balance-box">
                            <strong className="">
                                {t("Your Receive")} SVT
                            </strong>
                            <div className="balance-box ">
                                <div className="name">
                                     {t("Balance")}:
                                </div>
                                <div className="value">
                                    <span>{showNum(svtBalance)}</span>
                                </div>
                            </div>
                        </div>
                        <Form.Item
                            validateTrigger="onBlur"
                            validateFirst={true}

                        >
                            <div className="input-box">
                                <div className="exchangeAmount">
                                    {showNum(exchangeAmount * 0.8)}
                                </div>
                                <div className="right-tip">
                                    <img className="coin-icon" width={20} src={SVTCoinIcon} alt="" />
                                    SVT
                                </div>
                            </div>
                        </Form.Item>


                    </div>
                    {BigNumber(allowance).lt(exchangeAmount) && <Button className="submit-btn" onClick={() => {
                        handleApprove()
                    }}>Approve</Button>

                    }
                    {!BigNumber(allowance).lt(exchangeAmount)
                        && <Button className="submit-btn" onClick={() => {
                            handleSwap()
                        }}>{t("Swap")}</Button>
                    }


                </Form>
            </div>}
            {activeNav == 1 && <div className="operate-box">
                <div className="inline">
                    <div className="left">
                        {t("Total SBD Lock Amounts")}
                    </div>
                    <div className="right">
                        {showNum(lockInfo)} SBD
                    </div>
                </div>
                <div className="inline">
                    <div className="left">
                        {t("You can withdraw")}
                    </div>
                    <div className="right">
                        {(BigNumber(canWithdraw).toFixed(3))} SBD
                    </div>
                </div>
                <Form form={form} name="control-hooks" className="form">

                    <strong className="withdraw-header">
                        {t("Withdraw Amount(s)")}
                    </strong>
                    <div className="withdraw-part" style={{ marginTop: '8px' }}>

                        <Form.Item
                            name="claimAmount"
                            validateTrigger="onBlur"
                            validateFirst={true}

                        >
                            <div className="input-box">
                                <Input placeholder="0" value={inputValue}
                                    onChange={(e) => {
                                        getPoolBalance()
                                        setInputValue(e.target.value)
                                    }}></Input>
                                <div className="max-btn" onClick={() => {
                                    setInputValue(canWithdraw)
                                }}>
                                     {t("Max")}
                                </div>
                            </div>
                        </Form.Item>

                    </div>

                    <div className="withdraw-header" style={{ marginTop: '18px', fontWeight: "bold" }}>
                        {t("Burn SVT")}
                    </div>
                    <div className="withdraw-part" style={{ marginTop: '8px' }}>

                        <Form.Item
                            name="claimAmount"
                            validateTrigger="onBlur"
                            validateFirst={true}

                        >
                            <div className="input-box" style={{ fontSize: "20px", fontWeight: "bold" }}>
                                {inputValue}
                            </div>
                        </Form.Item>

                    </div>


                    <Button className="submit-btn" onClick={() => {
                        handleWithdraw()
                    }}>{t("Withdraw")}</Button>


                </Form>


            </div>}
            {activeNav == 0 &&
                <div className='panel-box'>
                    <div className='panel-container'>
                        <div className='panel-title'>
                            {t("Swap")}{t("Records")}
                        </div>
                        <div className='superdao-list-box nft-list-box'>
                            <div className='list-header nft-header'>
                                <div className='col'>
                                    {t("No.")}
                                </div>
                                {/*<div className='col'>*/}
                                {/*    Address*/}
                                {/*</div>*/}
                                <div className='col'>
                                    {t("Amount")}
                                </div>
                                <div className='col'>
                                    {t("Lock")} SBD
                                </div>
                                <div className='col'>
                                    {t("Receive")} SBD
                                </div>
                                <div className='col'>
                                    {t("Receive")} SVT
                                </div>
                                <div className='col'>
                                    {t("Time(UTC)")}
                                </div>
                            </div>
                            {exchangeRecord.map((item, index) => {
                                return (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    <div className='list-item nft-item' key={index}>
                                        <div className='col no'>
                                            {exchangeRecord.length - index}
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._amount).div(10 ** 18)  )}
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._lockSBD).div(10 ** 18)  )}
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._receiveSBD).div(10 ** 18)  )}

                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._receiveSVT).div(10 ** 18)  )}

                                        </div>
                                        {/*<div className='col address'>*/}
                                        {/*    <a target="_blank" href={develop.ethScan + "/address/" + item._user}>*/}
                                        {/*        {formatAddress(item._user)}*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}

                                        <div className='col'>
                                            {dealTime(item.blockTimestamp)}
                                        </div>
                                    </div>)
                            })}

                        </div>
                        <div className="pagination">

                            <Pagination current={curPage} showSizeChanger
                                onShowSizeChange={handleShowSizeChange}
                                onChange={onChangePage} total={total}
                                defaultPageSize={pageCount} />

                        </div>
                    </div>
                </div>
            }

            {activeNav == 1 &&
                <div className='panel-box'>
                    <div className='panel-container'>
                        <div className='panel-title'>
                            {t("Claim Records")}
                        </div>
                        <div className='superdao-list-box nft-list-box'>
                            <div className='list-header nft-header'>
                                <div className='col'>
                                    {t("No.")}
                                </div>
                                {/*<div className='col'>*/}
                                {/*    Address*/}
                                {/*</div>*/}
                                <div className='col'>
                                   {t("Amount")}
                                </div>
                                <div className='col'>
                                    {t("Burn SVT")}
                                </div>
                                <div className='col'>
                                     {t("Time(UTC)")}
                                </div>
                            </div>
                            { claimRecord.map((item, index) => {
                                return (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    <div className='list-item nft-item' key={index}>
                                        <div className='col no'>
                                            {claimRecord.length - index}
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._amount).div(10 ** 18)  )}
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._burnSVT).div(10 ** 18)  )}
                                        </div>

                                        {/*<div className='col address'>*/}
                                        {/*    <a target="_blank" href={develop.ethScan + "/address/" + item._user}>*/}
                                        {/*        {formatAddress(item._user)}*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}

                                        <div className='col'>
                                            {dealTime(item.blockTimestamp)}
                                        </div>
                                    </div>)
                            })}

                        </div>
                        <div className="pagination">

                            <Pagination current={curPage} showSizeChanger
                                onShowSizeChange={handleShowSizeChange}
                                onChange={onChangePage} total={total}
                                defaultPageSize={pageCount} />

                        </div>
                    </div>
                </div>}

        </SwapViewStyle>
    )
}
export default SwapView
