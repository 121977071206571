import styled from "styled-components";
import React, { useState } from 'react';
import { Component, useEffect, useReducer } from "react";
import english from "../../imgs/english.webp"
import {Button, Menu, Select} from 'antd';
import { useLocation,useNavigate } from "react-router-dom";
import develop from "../../env"
import navMap from "../../config/navMap";
import { useConnect } from "../../api/contracts";
import MNavListStyle from "./MNavListStyle";
import {useTranslation} from "react-i18next";



function getItem(label, key, type) {
    return {
        key,
        label,
        type,
    };
}




const NavList = (props) => {
    const { closeDialog } = props
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(["SBDPurchase"]);
    const [selectNav, setSelectNav] = useState("SBDPurchase");
    const [openKeys, setOpenKeys] = useState(['SBDPurchase']);
    const [lng, setLNG] = useState("zh")

    const rootSubmenuKeys = navMap.map(items => {
        return items.key
    });
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const history = useNavigate();
    const location = useLocation()
    const {i18n} = useTranslation();
    const { t } = useTranslation();
    const items = [
        getItem(t('Funding'), 'SBDPurchase',),
        getItem(t('SBDLock'), 'SBDLock',),
        getItem('NFT', 'NFTView',),
        getItem(t('Swap'), 'SwapView',),
        getItem(t('Bonus'), 'LeaderboardBonus',),
    ]
    const goPage = (obj) => {
        setSelectedKeys(obj.keyPath)
        history("/" + obj.key);
        setSelectNav(obj.keyPath[1])
    }
    const onOpenChange = (keys) => {
        console.log(keys)
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    useEffect(() => {
        let keyPath = []
        const curKey = location.pathname.substring(1, location.pathname.length)
        navMap.forEach(navObj => {
          
        })
    }, [])
    return (
        <MNavListStyle>
             <div className="mask" onClick={closeDialog }>

            </div>
            <div className="m-nav-box">
            
                <div
                    className="navBox"
                    // onClick={closeDialog }
                    style={{
                        width: 256,

                    }}
                >

                    <Menu
                          className="menu"
                          mode="inline"
                          defaultSelectedKeys={[]}
                          defaultOpenKeys={[selectNav]}
                          selectedKeys={selectedKeys}
                          openKeys={openKeys}
                          onOpenChange={onOpenChange}
                          theme="dark"
                          inlineCollapsed={collapsed}
                          items={items}
                          onClick={(e) =>{ goPage(e);closeDialog()}}
                    />
                    <Select
                        value={lng}
                        onChange={(value) => {
                            i18n.changeLanguage(value);
                            setLNG(value)
                        }}
                        className=' select-box-m'
                        options={[{label: "English", value: "en"},
                            {label: "中文", value: "zh"}]}
                    />
                </div>
            </div>
        </MNavListStyle>
    )

}

export default NavList
