import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Pagination} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import SwapViewStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import {showNum} from "../../../../utils/bigNumberUtil";
import BigNumber from "bignumber.js";
import SRTIcon from "../../../../imgs/SRT.png";
import download from "../../../../imgs/download.png";
import SBDCoinIcon from "../../../../imgs/SBD.png";
import SVTCoinIcon from "../../../../imgs/svt.png";
import ConnectWallet from "../../../../component/ConnectWallet/ConnectWallet";
import addressMap from "../../../../api/addressMap";
import {MaxUint256} from "../../../../config/constants";
import {getAllSwapRecords, getSwapRecords} from "../../../../graph/swapGQL";
import develop from "../../../../env";
import {formatAddress} from "../../../../utils/publicJs";
import {dealTime} from "../../../../utils/timeUtil";
import coinInfo from "../../../../config/coinInfo";
import {useTranslation} from "react-i18next";

const SwapView = (props) => {
    let {state, dispatch} = useConnect();
    const [poolSBDCoinBalance, setPoolSBDCoinBalance] = useState(0)
    const [SRTCoinBalance, setSRTCoinBalance] = useState(0)

    const [salePrice, setSalePrice] = useState(0)
    const [srtBalance, setSrtBalance] = useState(0)
    const [sbdBalance, setSBDBalance] = useState(0)

    const [svtBalance, setSVTBalance] = useState(0)
    const [exchangeAmount, setExchangeAmount] = useState(0)
    const [activeNav, setActiveNav] = useState(0)
    const [srtDecimal, setSRTDecimal] = useState(18)
    const [sbdDecimal, setSBDDecimal] = useState(18)
    const [inputValue, setInputValue] = useState(undefined)
    const [owner, setOwner] = useState("")
    const [allowance, setAllowance] = useState(0)
    const [adminCoin, setAdminCoin] = useState({})
    const [claimRecord, setClaimRecord] = useState([])
    const [exchangeRecord, setExchangeRecord] = useState([])


    const [claimRecordAll, setClaimRecordAll] = useState([])
    const [exchangeRecordAll, setExchangeRecordAll] = useState([])
    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [addressValue, setAddressValue] = useState(undefined)

    const [lockInfo, setLockInfo] = useState()
    const [canWithdraw, setCanwithdraw] = useState()

    const [form] = Form.useForm();
    const { t } = useTranslation();

    const location = useLocation()
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("Swap", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("Swap", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const getPoolBalance = async () => {

        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        setSRTDecimal(decimal)

        let contractTemp2 = await getContractByContract("erc20", addressMap["SBD"].address, state.api,)
        const decimal2 = await viewMethod(contractTemp2, state.account, "decimals", [])
        setSRTDecimal(decimal2)
        const balance = await getCoinInfo(addressMap["SBD"].address, addressMap["Swap"].address)
        const balance2 = await getCoinInfo(addressMap["SRT"].address, addressMap["Swap"].address)
        const allLock = await handleViewMethod("allLock", [])
        setPoolSBDCoinBalance(BigNumber(balance).minus(allLock / 10 ** 18).toString())
        setSRTCoinBalance(balance2)
        const mb = await getCoinInfo(addressMap["SBD"].address, state.account)
        const mb2 = await getCoinInfo(addressMap["SRT"].address, state.account)

        const svt = await getCoinInfo(addressMap["SVT"].address, state.account)

        setSBDBalance(mb)
        setSVTBalance(svt)
        setSrtBalance(mb2)
    }
    const getLockInfo = async () => {
        let lockInfo = await handleViewMethod("getUserLockAmount", [])
        const canClaim = await handleViewMethod("getClaimAmount", [])
        setCanwithdraw(BigNumber(canClaim).div(10 ** sbdDecimal).toString())
        lockInfo = (BigNumber(lockInfo).div(10 ** sbdDecimal)).toString()
        setLockInfo(lockInfo)
    }
    const getAdmin = async () => {

        const owner = await handleViewMethod("owner", [])
        setOwner(owner)

    }
    const handleSwap = async () => {
        await handleDealMethod("exchange", [BigNumber(exchangeAmount).multipliedBy(10 ** srtDecimal).toFixed(0).toString()])
        getPoolBalance()
        getLockInfo()
    }
    const handleDeposit= async () => {

        let contractTemp = await getContractByContract("erc20", addressValue, state.api,)
         await dealMethod(contractTemp, state.account, "transfer", [addressMap["Swap"].address,BigNumber(form.getFieldValue().amount).multipliedBy(10**adminCoin.decimal).toString()])
        getPoolBalance()
        getLockInfo()
    }
    const handleWithdraw = async () => {

        await handleDealMethod("withdraw", [addressValue,BigNumber(form.getFieldValue().amount).multipliedBy(10**adminCoin.decimal).toString()])
        getPoolBalance()
        getLockInfo()
    }
    const handleApprove = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["Swap"].address, MaxUint256])
        getAllowance()
    }
    const getAllowance = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap["Swap"].address])
        setAllowance(res)
    }
    const getAdminCoinInfo = async (addressValue) => {
        if (!state.api.utils.isAddress(addressValue)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", addressValue, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        const name = await viewMethod(contractTemp, state.account, "name", [])
        const symbol = await viewMethod(contractTemp, state.account, "symbol", [])
        let balance = await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["Swap"].address])

        balance = BigNumber(balance).div(10 ** decimal).toString()
        setAdminCoin({
            balance,
            decimal,
            name,
            symbol
        })

    }
    const getCoinInfo = async (coinAddr, toAddr) => {
        if (!state.api.utils.isAddress(coinAddr)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", coinAddr, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        let balance = await viewMethod(contractTemp, toAddr, "balanceOf", [toAddr])
        balance = BigNumber(balance).div(10 ** decimal)
        return balance

    }
    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().owner])
        getOwner()
    }

    const getOwner = async () => {
        const ownerAddr = await handleViewMethod("owner", [])
        setOwner(ownerAddr)
    }

    const getData = async (page) => {
        getPoolBalance()
        getLockInfo()
        getAllowance()
        getOwner()
        getAdmin()
    }


    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }

        const allRes = await getAllSwapRecords()

        if (allRes && allRes.data) {
            setExchangeRecordAll(allRes.data.exchanges)
            setClaimRecordAll(allRes.data.claims)
        }
        await getData()

    }, [state.account]);
    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    return (
        <SwapViewStyle>
            <div className="header-box">
                <div className="header-item">
                    <div className="name">
                        {t("Pool SBD Amounts")}
                    </div>
                    <div className="value">
                        {showNum(poolSBDCoinBalance, 2)} SBD
                    </div>
                </div>

            </div>
            <div className="content-item">
                <h3>Owner Address</h3>
                <Form form={form} name="control-hooks">
                    <div className="current">
                        <div className="name">
                            Current:
                        </div>
                        <div className="value">
                            {owner}
                        </div>
                    </div>
                    <Form.Item
                        name="owner"
                        label="owner address"
                        validateTrigger="onBlur"
                        validateFirst={true}
                        rules={[
                            {required: true, message: 'Please input owner Address!'},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
                <Button type="primary" onClick={() => {
                    transferOwnership()
                }}>
                     {t("Submit")}:
                </Button>
            </div>
            <div className="nav-box">
                <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                    setActiveNav(0)
                }}>
                    Swap
                </div>
                <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                    setActiveNav(1)
                }}>
                   {t("Withdraw")}
                </div>
            </div>


            {activeNav == 0 &&
                <div className='panel-box'>
                    <div className='panel-container'>
                        <div className='panel-title'>
                            All Swap Records
                        </div>
                        <div className='superdao-list-box nft-list-box'>
                            <div className='list-header nft-header'>
                                <div className='col'>
                                    {t("No.")}
                                </div>
                                <div className='col'>
                                    Address
                                </div>
                                <div className='col'>
                                   {t("Amount")}
                                </div>
                                <div className='col'>
                                    {t("Lock SBD")}
                                </div>
                                <div className='col'>
                                    Receive SBD
                                </div>
                                <div className='col'>
                                      {t("Receive SVT")}
                                </div>
                                <div className='col'>
                                     {t("Time(UTC)")}
                                </div>
                            </div>
                            {exchangeRecordAll.map((item, index) => {
                                return (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    <div className='list-item nft-item' key={index}>
                                        <div className='col no'>
                                            {exchangeRecordAll.length - index}
                                        </div>
                                        <div className='col address'>
                                            <a target="_blank" href={develop.ethScan + "/address/" + item._user}>
                                                {formatAddress(item._user)}
                                            </a>
                                        </div>
                                        <div className='col'>
                                            {showNum(item._amount / 10 ** 18)}
                                        </div>
                                        <div className='col'>
                                            {showNum(item._lockSBD / 10 ** 18)}
                                        </div>
                                        <div className='col'>
                                            {showNum(item._receiveSBD / 10 ** 18)}
                                        </div>
                                        <div className='col'>
                                            {showNum(item._receiveSVT / 10 ** 18)}
                                        </div>


                                        <div className='col'>
                                            {dealTime(item.blockTimestamp)}
                                        </div>
                                    </div>)
                            })}

                        </div>
                        <div className="pagination">

                            <Pagination current={curPage} showSizeChanger
                                        onShowSizeChange={handleShowSizeChange}
                                        onChange={onChangePage} total={total}
                                        defaultPageSize={pageCount}/>

                        </div>
                    </div>
                </div>}

            {activeNav == 1 &&
                <div className='panel-box'>
                    <div className='panel-container'>
                        <div className='panel-title'>
                             {t("Claim Records")}
                        </div>
                        <div className='superdao-list-box nft-list-box'>
                            <div className='list-header nft-header'>
                                <div className='col'>
                                    {t("No.")}
                                </div>
                                <div className='col'>
                                     {t("Address")}
                                </div>
                                <div className='col'>
                                      {t("Amount")}
                                </div>
                                <div className='col'>
                                    {t("Burn SVT")}
                                </div>
                                <div className='col'>
                                     {t("Time(UTC)")}
                                </div>
                            </div>
                            {claimRecordAll.map((item, index) => {
                                return (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    <div className='list-item nft-item' key={index}>
                                        <div className='col no'>
                                            {claimRecordAll.length - index}
                                        </div>
                                        <div className='col address'>
                                            <a target="_blank" href={develop.ethScan + "/address/" + item._user}>
                                                {formatAddress(item._user)}
                                            </a>
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._amount).div(10 ** 18)  )}
                                        </div>
                                        <div className='col'>
                                            {showNum(BigNumber(item._burnSVT).div(10 ** 18)  )}
                                        </div>


                                        <div className='col'>
                                            {dealTime(item.blockTimestamp)}
                                        </div>
                                    </div>)
                            })}

                        </div>
                        <div className="pagination">

                            <Pagination current={curPage} showSizeChanger
                                        onShowSizeChange={handleShowSizeChange}
                                        onChange={onChangePage} total={total}
                                        defaultPageSize={pageCount}/>

                        </div>
                    </div>
                </div>}
            <div className="operate-box">

                <Form form={form} name="control-hooks" className="form">

                    <strong className="withdraw-header">
                        Deposit / Withdraw Amount(s)
                        {t("Withdraw Amount(s)")}
                    </strong>
                    <div className="withdraw-part" style={{marginTop: '8px'}}>

                        <Form.Item
                            label="Coin Address"
                            validateTrigger="onBlur"
                            validateFirst={true}

                        >
                            <Input placeholder="addr" value={addressValue}
                                   onChange={(e) => {
                                       setAddressValue(e.target.value)
                                       getAdminCoinInfo(e.target.value)
                                   }}></Input>
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            label="Amount"
                            validateTrigger="onBlur"
                            validateFirst={true}
                            rules={[
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                    </div>
                    <div className="inline" style={{marginTop: "20px"}}>
                        <div className="left">
                            Name
                        </div>
                        <div className="right">
                            {adminCoin.name}
                        </div>
                    </div>
                    <div className="inline">
                        <div className="left">
                            Symbol
                        </div>
                        <div className="right">
                            {adminCoin.symbol}
                        </div>
                    </div>
                    <div className="inline">
                        <div className="left">
                            Decimals
                        </div>
                        <div className="right">
                            {adminCoin.decimal}
                        </div>
                    </div>
                    <div className="inline">
                        <div className="left">
                            Swap Pool Balance
                        </div>
                        <div className="right">
                            {showNum(adminCoin.balance)}
                        </div>
                    </div>
                    <Button className="submit-btn" onClick={() => {
                        handleDeposit()
                    }}>Deposit</Button>
                    <Button className="submit-btn" onClick={() => {
                        handleWithdraw()
                    }}>Withdraw</Button>


                </Form>

            </div>
        </SwapViewStyle>
    )
}
export default SwapView
