import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import FireLockStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import AddWhiteListAddr from "./component/AddWhiteListAddr";
import RemoveWhiteListAddr from "./component/RemoveWhiteListAddr";
import add from "../../../../imgs/add.png";
import cut from "../../../../imgs/remove.png";
import {SBDDecimals} from "../../../../config/constants";
import BigNumber from "bignumber.js";
import {useTranslation} from "react-i18next";

const FireLock = (props) => {
    let {state, dispatch} = useConnect();
    const [whitelist, setWhitelistArr] = useState([])
    const [blacklist, setBlacklistArr] = useState([])
    const [excludeHolderLists, setExcludeHolderLists] = useState([])

    const [isPause, setIsPause] = useState(false)
    const [marketAddress, setMarketAddress] = useState()

    const [developAddress, setDevelopAddress] = useState()
    const [fundAddress, setFundAddress] = useState()

    const [holderRewardCondition, setHolderRewardCondition] = useState()

    const [curNav, setCurNav] = useState(1)
    const [ownerAddr, setOwner] = useState("")

    const [ratioAmount, setratioAmount] = useState("")
    const [rewardLimit, set_rewardLimit] = useState("")
    const [isShowAddW, setIsShowAddW] = useState(false)


    const [isShowAdd, setShowAdd] = useState(false)
    const [isShowRemove, setShowRemove] = useState(false)

    const [feeReceiver, setReceiver] = useState("")
    const [isInFeeWhitelist, setIsInFeeWhitelist] = useState(false)
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const location = useLocation()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBD", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBD", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }


    const getData = async (page) => {
        getOwner()
        get_rewardLimit()
        getMarketAddress()
        getDevelopAddress()
        getFundAddress()
        getWhiteLists()
        getExcludeHolderLists()
        getHolderRewardCondition()
    }

    const dealNum = (num) => {
        return parseInt(num * 100) / 100
    }
    const get_rewardLimit  = async () => {
        const ratioAmount = await handleViewMethod("_rewardLimit", [])
        set_rewardLimit( BigNumber(ratioAmount).div(10**SBDDecimals).toString() )
    }

    const getOwner = async () => {
        const ownerAddr = await handleViewMethod("owner", [])
        setOwner(ownerAddr)
    }
    const getStatus = async () => {
        const res = await handleViewMethod("status", [])
        setIsPause(res)
    }
    const getMarketAddress= async () => {
        const res = await handleViewMethod("marketAddress", [])
        setMarketAddress(res)
    }
    const getWhiteLists= async () => {
        const res = await handleViewMethod("whiteLists", [])
        setWhitelistArr(res)
    }
    const getExcludeHolderLists= async () => {
        const res = await handleViewMethod("excludeHolderLists", [])
        setExcludeHolderLists(res)
    }

    const getBlackLists= async () => {
        const res = await handleViewMethod("blackLists", [])
        setBlacklistArr(res)
    }
    const getDevelopAddress= async () => {
        const res = await handleViewMethod("developAddress", [])
        setDevelopAddress(res)
    }
    const getHolderRewardCondition= async () => {
        const res = await handleViewMethod("holderRewardCondition", [])
        setHolderRewardCondition(BigNumber(res).div(10**18).toString())
    }
    const getFundAddress= async () => {
        const res = await handleViewMethod("fundAddress", [])
        setFundAddress(res)
    }



    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().owner])
        getOwner()
    }

    const setRewardLimit= async () => {
        await handleDealMethod("setRewardLimit", [BigNumber(form.getFieldValue().RewardLimit).multipliedBy(10**SBDDecimals).toFixed(0)])
        get_rewardLimit()
    }

    const addExcludeHolder = async () => {
        await handleDealMethod("setExcludeHolder", [form.getFieldValue().addExcludeHolder ,true])
        getExcludeHolderLists()
    }
    const removeExcludeHolder = async () => {
        await handleDealMethod("setExcludeHolder", [form.getFieldValue().ExcludeHolder ,false])
        getExcludeHolderLists()
    }
    const get_feeWhiteList = async () => {
       const res = await handleViewMethod("_feeWhiteList", [form.getFieldValue().feeWhiteList])
        setIsInFeeWhitelist(res)
    }

    const launch= async () => {
        await handleDealMethod("launch", [])
        getMarketAddress()
    }

    const handleSeDevelopAddress = async () => {
        await handleDealMethod("seDevelopAddress", [form.getFieldValue().DevelopAddress])
        getDevelopAddress()
    }
    const handleSetHolderRewardCondition= async () => {
        await handleDealMethod("setHolderRewardCondition", [BigNumber(form.getFieldValue().HolderRewardCondition ).multipliedBy( 10**SBDDecimals).toFixed(0)])
        getHolderRewardCondition()
    }
    const handleSetFundAddress = async () => {
        await handleDealMethod("setFundAddress", [form.getFieldValue().FundAddress])
        getFundAddress()
    }

    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()
    }, [state.account]);
    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    return (
        <FireLockStyle>
            {isShowAddW && <AddWhiteListAddr updateData={() => {
                getWhiteLists()
            }} closeDialog={() => {
                setIsShowAddW(false)
            }}/>}
            {isShowRemove && <RemoveWhiteListAddr updateData={() => {
            }} closeDialog={() => {
                setShowRemove(false)
            }}/>}
            <h1 className="title">
                SBD Admin
            </h1>
            <div className="panel-box">
                <div className="panel-container">
                    <div className="nav-list">
                        <div className={"nav-item " + (curNav == 1 ? "active" : "")} onClick={() => {
                            setCurNav(1)
                        }}>
                            Owner
                        </div>
                        <div className={"nav-item " + (curNav == 2 ? "active" : "")} onClick={() => {
                            setCurNav(2)
                        }}>
                            Set White Black List
                        </div>

                    </div>
                    {curNav == 1 && <div className="part1">

                        <div className="content-item">
                            <h3>Owner Address</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {ownerAddr}
                                    </div>
                                </div>
                                <Form.Item
                                    name="owner"
                                    label="owner address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                transferOwnership()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>
                        {/*<div className="content-item">*/}
                        {/*    <h3>Is Pause</h3>*/}
                        {/*    <Form form={form} name="control-hooks">*/}
                        {/*        <div className="current">*/}
                        {/*            <div className="name">*/}
                        {/*                Current:*/}
                        {/*            </div>*/}
                        {/*            <div className="value">*/}
                        {/*                {isPause?"Pause":"UnPause"}*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Form>*/}
                        {/*    <Button type="primary" className="max-btn" onClick={() => {*/}
                        {/*        setstatus()*/}
                        {/*    }}>*/}
                        {/*         {t("Submit")}:*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <div className="content-item">
                            <h3> launch</h3>
                            <Button type="primary" className="max-btn" onClick={() => {
                                launch()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> setRewardLimit</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {rewardLimit}
                                    </div>
                                </div>
                                <Form.Item
                                    name="RewardLimit"
                                    label="RewardLimit"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                setRewardLimit()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> setDevelopAddress</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {developAddress}
                                    </div>
                                </div>
                                <Form.Item
                                    name="DevelopAddress"
                                    label="DevelopAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSeDevelopAddress()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> setHolderRewardCondition</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {holderRewardCondition}
                                    </div>
                                </div>
                                <Form.Item
                                    name="HolderRewardCondition"
                                    label="HolderRewardCondition"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input placeholder="0.0001"/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetHolderRewardCondition()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> SetFundAddress</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {fundAddress}
                                    </div>
                                </div>
                                <Form.Item
                                    name="FundAddress"
                                    label="FundAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetFundAddress()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>


                    </div>}


                </div>
                {curNav == 2 && <div className="panel-container">
                    <h2>White List</h2>
                    <Table pagination={false} columns={whitelistColumns} dataSource={whitelist} />
                    <div className="content-item">
                        <h2> setFeeWhiteList  </h2>
                        <Button type="primary" className="max-btn" onClick={() => {
                            setIsShowAddW(true)
                        }}>
                            Add
                        </Button>
                    </div>


                    <div className="content-item">
                        <h2> Is In feeWhiteList</h2>
                        <Form form={form} name="control-hooks">
                            <div className="current">
                                <div className="name">
                                    Value:
                                </div>
                                <div className="value">
                                    {isInFeeWhitelist?"In":"Not In"}
                                </div>
                            </div>
                            <Form.Item
                                name="feeWhiteList"
                                label="address"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>

                        </Form>
                        <Button type="primary" className="max-btn" onClick={() => {
                            get_feeWhiteList()
                        }}>
                             {t("Submit")}:
                        </Button>
                    </div>




                    <h2>ExcludeHolder Lists</h2>
                    <Table pagination={false} columns={whitelistColumns} dataSource={excludeHolderLists} />



                    <div className="content-item">
                        <h2> addExcludeHolder</h2>
                        <Form form={form} name="control-hooks">
                            <div className="current">
                                <div className="name">
                                </div>
                                <div className="value">
                                </div>
                            </div>
                            <Form.Item
                                name="addExcludeHolder"
                                label="ExcludeHolder"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>

                        </Form>
                        <Button type="primary" className="max-btn" onClick={() => {
                            addExcludeHolder()
                        }}>
                             {t("Submit")}:
                        </Button>
                    </div>
                    <div className="content-item">
                        <h2> removeExcludeHolder</h2>
                        <Form form={form} name="control-hooks">
                            <div className="current">
                                <div className="name">
                                </div>
                                <div className="value">
                                </div>
                            </div>
                            <Form.Item
                                name="ExcludeHolder"
                                label="ExcludeHolder"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>

                        </Form>
                        <Button type="primary" className="max-btn" onClick={() => {
                            removeExcludeHolder()
                        }}>
                             {t("Submit")}:
                        </Button>
                    </div>

                </div>}

            </div>
        </FireLockStyle>
    )
}
export default FireLock
