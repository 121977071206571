import erc20Abi from "../abi/erc20.json";

import spbd from "../abi/spbd.json"
import lock from "../abi/oglock.json"

import erc1155 from "../abi/erc1155.json"
import SPT from "../abi/spt.json"
import SFT from "../abi/sft.json"
import SBD from "../abi/sbd.json"
import SRT from "../abi/SRT.json"
import SVT from "../abi/svt.json"

import Swap from "../abi/swap.json"
import BigNode from "../abi/bigNode.json"
import SmallNode from "../abi/smallNode.json"
import SupNode from "../abi/supNode.json"

import SBDLock from "../abi/sbdlock.json"
import NFTLock from "../abi/nftlock.json"
import NFTV3 from "../abi/NFTV3.json"
import SBDSFTLock from "../abi/sbdsftlock.json"
import NFTLockV3 from "../abi/nftlockV3.json"
import leaderboardBonus from "../abi/dividend.json"
import SPTIssue from "../abi/sptIssue.json";
let CONTRACTS


CONTRACTS = {
    erc20: {address: "", abi: erc20Abi},
    SBD: {address: "0x84F19f4Fd5Beb26c4ec5C9e3FEed6e052Fe9645a", abi: SBD},

    spbd: {address: "0xcbe86834544cc0263b4a8315ec8a789206651b6e", abi: spbd},
    lock: {address: "0x34cae606E47423ae797F574E4C6a0C2069a6f818", abi: lock},
    SRT: {address: "0x4069d0926Cc454e3d801F2999049bBD8B2c55509", abi: SRT},

    Swap: {address: "0xcD95DFecE754E61C9e8981cE238e165B66A6088d", abi: Swap},


    SBDLock: {address: "0x59DFA1145FC634b5EA994dd19b9C2aE6eAA32a33", abi: SBDLock},

    // main
    // SBD:{address: "0xaff416794e0b7cb2d1f842bcaea698ef1114a3f7", abi: SBD},
    // main2
    // SBD:{address: "0x7b54D42868635c6550E8C37216541312F6D8190D", abi: SBD},
    USDT: {address: "0x55d398326f99059fF775485246999027B3197955", abi: SBD},
    SVT: {address: "0x7c893B81eF793a6541Cdeca519c385db587700F8", abi: SVT},
    SFT: {address: "0xE254428Df24FCd41674e30852779e4BDEc501801", abi: SFT},
    supnode: {address: "0x527efe3942A9ca589b12378c2f2DdAB45ca8E04f", abi: SupNode},
    bignode: {address: "0x628a2258fb15aa17C401acF4494AC5c8d8EA4374", abi: BigNode},
    smallnode: {address: "0x41deABa667304cE5bFE580508090918329aa10E4", abi: SmallNode},
    // NFT
    NFTLock: {address: "0x07cD31859ecfdB8d269842B3FFE0cf76DbbbEcaF", abi: NFTLock},

    NFTLockV2: {address: "0x259a355BB3666A416c9D39B4a85Dba1Ee5464AB8", abi: NFTLock},
    NFTLockV3: {address: "0x306FFdE7Ca034aCB0645ed34009960013A025597", abi: NFTLockV3},

    supnodeV1: {address: "0x527efe3942A9ca589b12378c2f2DdAB45ca8E04f", abi: SupNode},
    bignodeV1: {address: "0x628a2258fb15aa17C401acF4494AC5c8d8EA4374", abi: BigNode},
    smallnodeV1: {address: "0x41deABa667304cE5bFE580508090918329aa10E4", abi: SmallNode},
    supnodeV2: {address: "0xa672f14b7a8a18923B09d9C4B9D2a1a4cF3601FB", abi: SupNode},
    bignodeV2: {address: "0x53b34D7fb7F83F6c3699e1f59Bf5bEF9DabC635D", abi: BigNode},
    smallnodeV2: {address: "0xC4488873B462D2225CC4b7993D9aD59F0e8d5274", abi: SmallNode},

    supnodeV3: {address: "0x7eAdE29199e281C76A3E43a3cE0f479fdB58D644", abi: NFTV3},
    bignodeV3: {address: "0x361Bc3048D9a30762598c2546F38D48881e44E4f", abi: NFTV3},
    smallnodeV3: {address: "0x565abC0177EB65acac552a6F36b4472A1356B5a2", abi: NFTV3},

    SBDSFTLock: {address: "0xDe506Ae69819B280635012aD72Ad86e3fD8E922d", abi: SBDSFTLock},
    SPTIssue: {address: "0xEFE9fc692F40B2a267fC91a87DaEf03d4D66Bec8", abi: SPTIssue},

    // SPT: {address: "0xB2210e8aaACd56e34640a344e2074c529b2d8C0C", abi: SPT},

    SPT: {address: "0x507127334Ee884a9450bCa02AdB15C518590e67d", abi: SPT},
    leaderboardBonus: {address: "0x2eC0039aEBE02581177b1BffdcAb5Ac2069206dE", abi: leaderboardBonus},
    LeaderboardBonus1: {address: "0xB7C4f947Ce038951c092545eAE7A8433E7d73194", abi: leaderboardBonus},
    LeaderboardBonus2: {address: "0x9102771c00C74e0e4E377914299e798b1421774e", abi: leaderboardBonus},
    erc1155: {abi: erc1155},

}
export default CONTRACTS
