import React, {useEffect, useState, useRef} from "react";
import {useConnect} from "../../../../../api/contracts";
import {dealMethod, dealPayMethod, viewMethod} from "../../../../../utils/contractUtil";
import {ETHPriceDecimals, MaxUint256, SBDDecimals} from "../../../../../config/constants";

import {dealTime} from "../../../../../utils/timeUtil";
import addressMap from "../../../../../api/addressMap"
import {getContractByName, getContractByContract} from "../../../../../api/connectContract";
import judgeStatus from "../../../../../utils/judgeStatus";
import {showNum} from "../../../../../utils/bigNumberUtil";
import {formatAddress} from "../../../../../utils/publicJs";
import {
    getMiningRecord,
    getWithdrawRecord,
    getWithdrawSrtRecord,
    getMyMiningRecord,
    getMyWithdrawRecord,
    getMyWithdrawSrtRecord
} from "../../../../../graph/SBDLockGQL";
import BigNumber from "bignumber.js";
import {Button, Form, Input, Select, Pagination, Empty, Modal, message, notification} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import SBDLockV3Style from './SBDLockStyle'
import {useTranslation} from "react-i18next";


const SBDLock = (props) => {
    let {state, dispatch} = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [curOrderId, setCurOrderId] = useState();

    const [adminAddr, setAdmin] = useState("");

    const [curPage, setCurPage] = useState(1);
    const [activeNav, setActiveNav] = useState(0);
    const [indexArr, setIndexArr] = useState([]);

    const [curIndex, setCurIndex] = useState(0);

    const [sbdRewards, setSbdRewardsTime] = useState([])
    const [sbdRewardsNum, setSbdRewardsNum] = useState([])
    const [sbdData, setSbdData] = useState([]);

    const [weight, setWeight] = useState(1)
    const [sbdSrt, setSbdSrt] = useState();
    const [svtAmount, setSvtAmount] = useState();

    const [allowanceNum, setAllowanceNum] = useState(0);

    const [allRecords, setAllRecords] = useState([]);
    const [myRecords, setMyRecords] = useState([]);
    const [myOrderArr, setMyOrderArr] = useState([]);
    const [myWithRecords, setMyWithRecords] = useState([]);

    const [withRecords, setWithRecords] = useState([]);
    const [srtRecords, setSrtRecords] = useState([]);
    const [myClaimRecords, setMyClaimRecords] = useState([]);

    const [claimWith, setClaimWith] = useState();
    const [annualized, setAnnualized] = useState();

    const [periodValue, setPeriodValue] = useState('0');
    const [listNum, setListNum] = useState('0');

    const [sbdBalance, setSbdBalance] = useState();
    const [srtBalance, setSrtBalance] = useState();
    const [svtBalance, setSvtBalance] = useState();
    const [receiveSVT, setReceiveSVT] = useState();
    const [inputValue, setInputValue] = useState()
    
    const [pendingSrt, setPendingSrt] = useState()

    const [listActiveNav, setListActiveNav] = useState(0);
    const [ordersActiveNav, setOrdersActiveNav] = useState(0);
    const [withdrawActiveNav, setWithdrawActiveNav] = useState(0);
    const [claimActiveNav, setClaimActiveNav] = useState(0);
    const [form] = Form.useForm();
    const history = useNavigate()
    const { t } = useTranslation();

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const lockList = [
        {
            value: '0',
            label: t('Demand deposits'),
        },
        {
            value: '1',
            label: '1 Month',
        },
        {
            value: '3',
            label: '3 Month',
        },
        {
            value: '6',
            label: '6 Month',
        },
        {
            value: '9',
            label: '9 Month',
        },
        {
            value: '12',
            label: '12 Month',
        },
        {
            value: '15',
            label: '15 Month',
        },
        {
            value: '18',
            label: '18 Month',
        },
        {
            value: '24',
            label: '24 Month',
        }
    ]


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const goPage = (url) => {
        history(url)
    }

    const onChangePage = async (page) => {
        await setCurPage(page)
        MiningRecord(curPage, page)
        withOrderRecords(curPage, page)
        withSrtRecords(curPage, page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        MiningRecord(count, curPage)
        withOrderRecords(count, curPage)
        withSrtRecords(count, curPage)

    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDLockV3", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDLockV3", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const handleApprove = async () => {
        let contractTemp = await getContractByName("SBD", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['SBDLockV3'].address, MaxUint256])
        getAllowance()
    }
    const getAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setAdmin(admin)
    }
    const handleAllowance = async () => {
        let contractTemp = await getContractByName("SBD", state.api,)
        const allowanceNum = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap['SBDLockV3'].address])
        setAllowanceNum(BigNumber(allowanceNum).div(10 ** 18))
    }

    const getAllowance = async () => {
        let contractTemp = await getContractByName("SBD", state.api,)
        let res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap['SBDLockV3'].address])
        setAllowanceNum(res)
    }

    const getInitAmount = async () => {
        let contractTemp = await getContractByName('SRT', state.api,)
        let initSrtAmount = await viewMethod(contractTemp, state.account, "totalSupply", [])
        setSbdSrt(BigNumber(initSrtAmount).div(10 ** 18).toString())

    }

    const getReward = async () => {
        let length = await handleViewMethod('getRewardLength', [])
        let rewardSrt = [], endTimeArr = [], rewardsNumArr = [], tempArr = []

        for (let i = 0; i < length; i++) {

            let rewardTime = await handleViewMethod("rewardTime", [i])
            let rewardsTimes = await handleViewMethod("startRewardTime", [i])

            let rewardsNum = await handleViewMethod("reward", [i])
            tempArr.push({
                value: i + 1,
                label: i + 1 + "#",
            })
            endTimeArr.push(rewardsTimes)
            rewardsNumArr.push(BigNumber(rewardsNum).div(10 ** 18).toString())
            rewardSrt.push(BigNumber(rewardTime).div(86400).toString())
        }
        setIndexArr(tempArr)
        setSbdRewardsTime(endTimeArr)
        setSbdData(rewardSrt)
        setSbdRewardsNum(rewardsNumArr)
    }

    const userSBDBalance = async () => {
        let contracts = await getContractByName('SBD', state.api,)
        let userLock = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        setSbdBalance(BigNumber(userLock).div(10 ** 18).toString())
    }

    const userSRTBalance = async () => {
        let contracts = await getContractByName('SRT', state.api,)
        let userLock = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        setSrtBalance(BigNumber(userLock).div(10 ** 18).toString())
    }

    const userSVTBalance = async () => {
        let contracts = await getContractByName('SVT', state.api,)
        let userLock = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        setSvtBalance(BigNumber(userLock).div(10 ** 18).toString())
    }

    const getPeriod = async (value) => {
        setPeriodValue(value)
        userWeigh(value)

    };

    const usersLock = async () => {
        if (!form.getFieldValue().locksbd || BigNumber(form.getFieldValue().locksbd).multipliedBy(10 ** 18).toFixed(0) <= 0) {
            message.error("Input amount")
            return
        }
        await handleDealMethod('lock', [periodValue, BigNumber(form.getFieldValue().locksbd).multipliedBy(10 ** 18).toFixed(0).toString()])

        setTimeout(() => {
            MiningRecord()
            getUserLock()
            userSVTBalance()
        }, 500)
    }

    const userWeigh = async (periodValue) => {
        if(periodValue){
            let res = await handleViewMethod('Weights', [periodValue])
            setWeight(res)
        }

    }

    const getPendingSrt = async () => {
        let res = await handleViewMethod('pendingSrt', [state.account])
        console.log(res)
        setPendingSrt(BigNumber(res).div(10 ** 18).toFixed(3))
    }

    const getSbdWithdraw = async () => {
        let res = await handleViewMethod('canClaimSbd', [state.account])
        setClaimWith(res)
    }
    const getAnnualized  = async () => {
        try{
            let res = await handleViewMethod('outPutAnnualized', [])
            setAnnualized(res)
        }catch (e) {
            
        }
    }
    const getList = async (value) => {
        setListNum(value)
    };

    const userSvt = async () => {
        let res = (form.getFieldValue().locksbd) * (weight)
        setReceiveSVT(res)
    }

    const getSrt = async () => {
        await handleDealMethod('ClaimSrt', [])
        getPendingSrt()
        setTimeout(()=>{
            withSrtRecords()
        },500)
    }
    const updatePower = async () => {
        await handleDealMethod('updatePower', [state.account])
        getPendingSrt()

    }

    const getWithSBD = async () => {
        // console.log(typeof(showNum(form.getFieldValue().withNumber)));
        await handleDealMethod('withdraw', [curOrderId])
        getUserLock()
        withOrderRecords()

    }

    const MiningRecord = async () => {
        let records = await getMiningRecord(pageCount, curPage)
        let res = records.data.lockRecords
        let myrecords = await getMyMiningRecord(state.account)
        let res2 = myrecords.data.lockRecords
        setMyRecords(res2)
        setAllRecords(res)

    }
    const getUserLock = async () => {
        let length = await handleViewMethod('getUserLockLength', [state.account])
        let tempArr = []
        for (let i = 0; i < length; i++) {
            const res = await handleViewMethod('userLockInfo', [state.account, i])
            tempArr.push(res)
        }
        setMyOrderArr(tempArr)
    }

    const withOrderRecords = async () => {
        let records = await getWithdrawRecord(pageCount, curPage)
        let res = records.data.withdrawRecords
        let myrecords = await getMyWithdrawRecord(state.account)
        let res2 = myrecords.data.withdrawRecords
        setWithRecords(res)
        setMyWithRecords(res2)
    }


    const withSrtRecords = async () => {
        let records = await getWithdrawSrtRecord(pageCount, curPage)
        if (records.data) {
            let res = records.data.claimSrtRecords
            setSrtRecords(res)
        }
        let myrecords = await getMyWithdrawSrtRecord(state.account)


        if (myrecords.data) {
            let res2 = myrecords.data.claimSrtRecords
            setMyClaimRecords(res2)
        }


    }

    const getMaxValue = async () => {
        let res = form.getFieldValue().withAmounts
    }


    useEffect(() => {
        if(inputValue>0&&weight>0){
            userSvt()

        }
        }, [inputValue, weight]
    )
    useEffect(async () => {
        MiningRecord()
        withOrderRecords()
        withSrtRecords()
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getAdmin()
        // getReward()
        userSBDBalance()
        userSVTBalance()
        getInitAmount()
        // getSbdWithdraw()
        getAnnualized()
        userWeigh()
        userSRTBalance()
        getAllowance()
        // getPendingSrt()
        getUserLock()
        handleAllowance()
    }, [state.account]);

    return (
        <SBDLockV3Style>

            <Modal title="Withdraw" className="withdraw-modal" open={isModalOpen} onOk={handleOk} footer={null}
                   onCancel={handleCancel}>
                <div className="withdraw-box">
                    <div className="withsbd" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p className="name" style={{color: 'rgba(138, 128, 128, 1)'}}> {t("You Can Withdraw SBD")}:</p>
                        <p className="modal-value">{showNum(BigNumber(claimWith).div(10 ** 18))}</p>
                    </div>
                    <div className="nft-balance" style={{marginTop: '2em'}}>
                        <p className="name">Withdraw Amounts</p>
                    </div>
                    <Form>
                        <div className="max-box">
                            <Form.Item
                                name="withNumber"
                                validateTrigger="onBlur"
                                validateFirst={true}

                            >
                                {showNum(BigNumber(claimWith).div(10 ** 18))}
                            </Form.Item>
                        </div>
                        <div className="nft-balance" style={{marginTop: '2em'}}>
                            <p className="name">Burn SVT</p>
                        </div>
                        <div className="max-box">
                            <Form.Item
                                name="burn-vst"
                                validateTrigger="onBlur"
                                validateFirst={true}

                            >
                                {svtAmount}
                            </Form.Item>
                        </div>
                        <Button onClick={() => {
                            getWithSBD()
                        }} type="primary" className="lock-btn">
                           {t("Withdraw")}
                        </Button>
                    </Form>
                </div>
            </Modal>
            <div className="panel-box">
                <div className="panel-title">
                    <span>SBD Lock Mining</span>
                    {
                        adminAddr.toString().toLowerCase()==state.account.toLowerCase()&&
                        <div className="lockadmin" onClick={() => {
                            goPage('/SBDLockV3Admin')
                        }}>
                            Admin
                        </div>
                    }

                </div>

                <div className="panel-container">
                    <div className="nft-box">
                        <div className="nftlock">
                            <div className="nft-title">
                                <span>SBD Lock Mining Pool</span>
                                <Select
                                    onChange={(val) => {
                                        setCurIndex(val - 1)
                                    }}
                                    options={indexArr}
                                    style={{
                                        width: "13%",
                                        color: '#FFF'
                                    }}
                                    className="chooseMonth"
                                    defaultValue={"Check"}
                                    filterOption={(indexArr, option) =>
                                        option.value.indexOf(indexArr) !== -1 &&
                                        /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(indexArr)
                                    }
                                />
                            </div>
                            <div className="nft-content">
                                <p className="name">{t("SRT Amounts")}</p>
                                <p className="value">
                                    {sbdRewardsNum.length > 0 ? showNum(sbdRewardsNum[curIndex]) : ""}

                                </p>
                            </div>
                            <div className="nft-content">

                                <p className="name">{t("Rewards Time")}</p>
                                <p className="value">
                                    {sbdData.length > 0 ? BigNumber(sbdData[curIndex]).toFixed(0,BigNumber.ROUND_CEIL) +" Days": ""}
                                </p>
                            </div>
                            <div className="nft-content">
                                <p className="name">Rewards SRT(Day)</p>
                                <p className="value">{sbdData.length > 0 ? (BigNumber(sbdRewardsNum[curIndex]).div(sbdData[curIndex]).toFixed(2)) : ""}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name"> {t("Annualized")}</p>
                                <div className="value">{BigNumber(annualized).div(100).toFixed(2)} %</div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-box">
                        <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                            setActiveNav(0)
                        }}>
                            SBD {t("Lock Mining")}
                        </div>
                        <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                            setActiveNav(1)
                        }}>
                           {t("Withdraw")} SBD
                        </div>
                        <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                            setActiveNav(2)
                        }}>
                            Claim SRT
                        </div>
                    </div>
                    {activeNav == 0 && <div>
                        <div className="lock-box1">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">Your Lock SBD</p>
                                        <p className="value"> {t("Balance")}: {showNum(sbdBalance)}</p>
                                    </div>
                                    <div className="max-box">
                                        <Form.Item
                                            name="locksbd"
                                            validateTrigger="onBlur"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input
                                                    value={inputValue}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setInputValue(e.target.value)
                                                    }}/>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className="nft-balance">
                                        <p className="name">Lock Period</p>
                                        <p className="value">Weight: {weight}</p>
                                    </div>
                                    <div className="small-nft">
                                        <div className="input-box">
                                            <Select
                                                value={periodValue}

                                                onChange={(e) => {
                                                    getPeriod(e)
                                                }}

                                                style={{
                                                    width: "100%",
                                                    color: '#FFF'
                                                }}
                                                options={lockList}

                                                filterOption={(periodValue, option) =>
                                                    option.value.indexOf(periodValue) !== -1 &&
                                                    /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(periodValue)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">You Receive SVT</p>
                                        <p className="value"> {t("Balance")}: {showNum(svtBalance)}</p>
                                    </div>
                                    <div className="max-box">
                                        <Form.Item
                                            name="nftAmount"
                                            validateTrigger="onBlur"
                                            validateFirst={true}

                                        >
                                            <div className="current">
                                                <p>{receiveSVT}  </p>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    {BigNumber(allowanceNum).lt(inputValue) ? <Button onClick={() => {
                                        handleApprove()
                                    }} type="primary" className="lock-btn">
                                        Approve
                                    </Button> : <Button onClick={() => {
                                        usersLock()
                                    }} type="primary" className="lock-btn">
                                        Lock Mining
                                    </Button>}


                                </div>
                            </Form>
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                Mining Records
                            </h1>

                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (listActiveNav == 0 ? "active" : "")}
                                     onClick={() => {
                                         setListActiveNav(0)
                                     }}>
                                    {t("All Records")}
                                </div>
                                <div className={"list-nav-item " + (listActiveNav == 1 ? "active" : "")}
                                     onClick={() => {
                                         setListActiveNav(1)
                                     }}>
                                    {t("My Records")}
                                </div>
                            </div>
                            {
                                listActiveNav == 0 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                         {t("Address")}
                                                </div>

                                                <div className="col">
                                                    {t("Lock SBD")}
                                                </div>

                                                <div className="col">
                                                      Period({t("month")})
                                                </div>
                                                <div className="col">
                                                      {t("Weight")}
                                                </div>
                                                <div className="col">
                                                      {t("Receive SVT")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {allRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : allRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.lockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period!=0?BigNumber(item.period).div(2592000).toString() +" Month":t("Demand deposits") }
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.receiveSVT).div(10 ** 18).toString()}

                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                            {
                                listActiveNav == 1 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    {t("Lock SBD")}
                                                </div>

                                                <div className="col">
                                                      Period({t("month")})
                                                </div>
                                                <div className="col">
                                                      {t("Weight")}
                                                </div>
                                                <div className="col">
                                                      {t("Receive SVT")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {myRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.lockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period!=0?BigNumber(item.period).div(2592000).toString() +" Month":t("Demand deposits") }

                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.receiveSVT).div(10 ** 18).toString()}

                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    }
                    {activeNav == 1 && <div>
                        <div className="lock-box">
                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (ordersActiveNav == 0 ? "active" : "")}
                                     onClick={() => {
                                         setOrdersActiveNav(0)
                                     }}>
                                    All Orders
                                </div>
                                <div className={"list-nav-item " + (ordersActiveNav == 1 ? "active" : "")}
                                     onClick={() => {
                                         setOrdersActiveNav(1)
                                     }}>
                                    My Orders
                                </div>
                            </div>
                            {
                                ordersActiveNav == 0 && <div className="superdao-list-box nft-list">
                                    <div className='list-headert'>
                                        <div className="list-header all-records-header">
                                            <div className="col">
                                                {t("No.")}
                                            </div>


                                            <div className="col">
                                                {t("Lock SBD")}
                                            </div>

                                            <div className="col">
                                                Period({t("month")})
                                            </div>
                                            <div className="col">
                                                  {t("Weight")}
                                            </div>
                                            <div className="col">
                                                  {t("Receive SVT")}
                                            </div>

                                            <div className="col">
                                                 {t("Time(UTC)")}
                                            </div>

                                        </div>
                                        {myOrderArr.length == 0 ? <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myOrderArr.map((item, index) => {
                                            return (
                                                <div className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>

                                                    <div className="col">
                                                        {showNum(BigNumber(item.amount).div(10 ** 18).toString())}
                                                    </div>
                                                    <div className="col">
                                                        {item.lockTime!=0?BigNumber(item.lockTime).div(2592000).toString() +" Month":t("Demand deposits") }
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            showNum(BigNumber(item.svtAmount).div(10 ** 18).toString())

                                                        }

                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.lockStartTime)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }

                                    </div>

                                </div>
                            }
                            {
                                ordersActiveNav == 1 && <div className="superdao-list-box orders-list">
                                    <div className='list-headert1'>
                                        <div className="list-header my-records-header">
                                            <div className="col">
                                                {t("No.")}
                                            </div>


                                            <div className="col">
                                                {t("Lock SBD")}
                                            </div>

                                            <div className="col">
                                                Period({t("month")})
                                            </div>
                                            <div className="col">
                                                  {t("Weight")}
                                            </div>
                                            <div className="col">
                                                  {t("Receive SVT")}
                                            </div>

                                            <div className="col">
                                                 {t("Time(UTC)")}
                                            </div>
                                            <div className="col">
                                                Operation
                                            </div>

                                        </div>

                                        {myOrderArr.length == 0 ? <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myOrderArr.map((item, index) => {
                                            return (
                                                <div className="list-item my-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col">
                                                        {showNum(BigNumber(item.amount).div(10 ** 18).toString())}
                                                    </div>
                                                    <div className="col">
                                                        {item.lockTime!=0?BigNumber(item.lockTime).div(2592000).toString() +" Month":t("Demand deposits") }

                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {showNum(BigNumber(item.svtAmount).div(10 ** 18).toString())}
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.lockStartTime)}
                                                    </div>
                                                    <div className="col">

                                                        {
                                                            BigNumber(item.amount).eq(0) ? <Button onClick={() => {
                                                                    setCurOrderId(index)
                                                                    setIsModalOpen(true);
                                                                }} type="primary" disabled>
                                                                    Extracted
                                                                </Button>
                                                                :
                                                                <Button onClick={() => {
                                                                    setCurOrderId(index)
                                                                    setIsModalOpen(true);
                                                                    setSvtAmount(showNum(BigNumber(item.svtAmount).div(10 ** 18).toString()))
                                                                }} className="lock-btn">
                                                                   {t("Withdraw")}
                                                                </Button>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        })
                                        }

                                    </div>

                                </div>

                            }
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Withdraw Records")}
                            </h1>

                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (withdrawActiveNav == 0 ? "active" : "")}
                                     onClick={() => {
                                         setWithdrawActiveNav(0)
                                     }}>
                                    All Orders
                                </div>
                                <div className={"list-nav-item " + (withdrawActiveNav == 1 ? "active" : "")}
                                     onClick={() => {
                                         setWithdrawActiveNav(1)
                                     }}>
                                    My Orders
                                </div>
                            </div>
                            {
                                withdrawActiveNav == 0 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    {t("Lock SBD")}
                                                </div>

                                                <div className="col">
                                                    Period
                                                </div>
                                                <div className="col">
                                                      {t("Weight")}
                                                </div>
                                                <div className="col">
                                                    {t("Burn SVT")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {withRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : withRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.unLockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period}
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.burnSVT).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}


                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                            {
                                withdrawActiveNav == 1 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    {t("Lock SBD")}
                                                </div>

                                                <div className="col">
                                                    Period
                                                </div>
                                                <div className="col">
                                                      {t("Weight")}
                                                </div>
                                                <div className="col">
                                                    {t("Burn SVT")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {myWithRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myWithRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.unLockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period}
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.burnSVT).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}


                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                    }
                    {activeNav == 2 && <div>
                        <div className="lock-box1">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">You Can Claim SRT</p>
                                        <p className="value"> {pendingSrt}</p>
                                    </div>

                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">  {t("Claim Amounts")}:</p>
                                        <p className="value"> {t("Balance")}: {showNum(srtBalance)}</p>
                                    </div>
                                    {/* <div className="max-box">
                                        <Form.Item
                                            name="withAmounts"
                                            validateTrigger="onBlur"
                                            validateFirst={true}

                                        >
                                            <div className="input-box">
                                                <Input
                                                    placeholder="0"
                                                    onChange={(e) => {

                                                    }} />
                                                <div className="max-btn" onClick={() => {
                                                    getMaxValue()
                                                }}>
                                                    Max
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div> */}
                                    {/*<Button onClick={() => {*/}
                                    {/*    updatePower()*/}
                                    {/*}} type="primary" className="lock-btn">*/}
                                    {/*    UpdatePower*/}
                                    {/*</Button>*/}
                                    <Button style={{marginTop:"10px"}} onClick={() => {
                                        getSrt()
                                    }} type="primary" className="lock-btn">
                                        Claim
                                    </Button>

                                </div>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Claim Records")}
                            </h1>

                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (claimActiveNav == 0 ? "active" : "")}
                                     onClick={() => {
                                         setClaimActiveNav(0)
                                     }}>
                                    {t("All Records")}
                                </div>
                                <div className={"list-nav-item " + (claimActiveNav == 1 ? "active" : "")}
                                     onClick={() => {
                                         setClaimActiveNav(1)
                                     }}>
                                    {t("My Records")}
                                </div>
                            </div>
                            {
                                claimActiveNav == 0 && <div>
                                    <div className="superdao-list-box withdraw-list">
                                        <div className='list-headert2'>
                                            <div className="list-header withall-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    SRT  {t("Amounts")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {srtRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : srtRecords.map((item, index) => {
                                                return (<div key={index} className="list-item withall-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.srtAmount).div(10 ** 18).toFixed()}
                                                    </div>

                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }

                            {
                                claimActiveNav == 1 && <div>
                                    <div className="superdao-list-box withdraw-list">
                                        <div className='list-headert2'>
                                            <div className="list-header withall-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    SRT  {t("Amounts")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {myClaimRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myClaimRecords.map((item, index) => {
                                                return (<div key={index} className="list-item withall-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.srtAmount).div(10 ** 18).toFixed()}
                                                    </div>

                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    }

                </div>


            </div>
        </SBDLockV3Style>
    )
}

export default SBDLock;