import React, { useEffect, useRef, useState } from 'react';
import { formatAddress } from "../../../../utils/publicJs";
import { useConnect } from "../../../../api/contracts";
import {
    Button,
    message,
    Form,
    Input, Modal,
    Empty
} from 'antd';
import { getContractByName, getContractByContract } from "../../../../api/connectContract";
import { dealMethod, dealPayMethod, viewMethod } from "../../../../utils/contractUtil"
import develop from "../../../../env";
import OgSetActiveStyle from "./OgSetActiveStyle";
import { getAllRegisters } from "../../../../graph/purChaseGQL";
import sc from "../../../../imgs/sc.png"
import xz from "../../../../imgs/xz.png"
import wxz from "../../../../imgs/wxz.png"
import add from "../../../../imgs/add.png"
import cut from "../../../../imgs/remove.png"
import {useTranslation} from "react-i18next";

const AddThreeWhiteList = ({ allRecords, isThreeAdmin }) => {
    let { state, dispatch } = useConnect();
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [curWhiteUser, setCurWhiteUser] = useState("")
    const [maxFiveAdmin, setMaxFiveAdmin] = useState(0)

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isDelMolOpen, setDelOpen] = useState(false)
    const [isAddMolOpen, setAddOpen] = useState(false)
    const [activeArr, setActiveArr] = useState([])
    const { t } = useTranslation();

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const getMaxFiveAdmin = async () => {
        let res = await handleViewMethod("maxFive", [])
        setMaxFiveAdmin(res)
    }
    const getActivateAccount = async () => {
        const refRes = await getAllRegisters(state.account)
        try {
            const refArr = refRes.data.allRegisters
            let myActiveArr = []
            const res = await handleViewMethod("getActivateAccount", [])

            res.forEach(account => {
                refArr.forEach(refItem => {
                    if (refItem._user.toLowerCase() == account.toLowerCase()) {
                        console.log(refItem)
                        if (!myActiveArr.includes(account)) {
                            myActiveArr.push(account)

                        }
                    }
                })
            })

            // if (!isThreeAdmin) {
            //     myActiveArr = res
            // }
            setActiveArr(myActiveArr)
        } catch (e) {
            console.log(e)
        }
    }
    const handleSetActive = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        if (isThreeAdmin) {
            await handleDealMethod("setActivateAccountForLThree", [arr])

        } else {
            console.log("setActivateAccountForL2")
            await handleDealMethod("setActivateAccountForLTwo", [arr])
        }
        getActivateAccount()
    }

    const removeActive = async () => {
        await handleDealMethod("removeWhiteListBatch", [[curWhiteUser]])
        setDelOpen(false)
        getActivateAccount()
    }

    const deleteWhite = async (user) => {
        setCurWhiteUser(user)
        setDelOpen(true)
    }

    useEffect(() => {
        if (!state.account) return
        getActivateAccount()
        // getMaxFiveAdmin()
    }, [state.account]);


    return (
        <OgSetActiveStyle>

            <div className="part3">
                <Modal className="model-dialog" title="Delete" open={isDelMolOpen} onOk={removeActive}
                    onCancel={() => {
                        setDelOpen(false)
                    }}>
                    <h3>
                        {t("Address")}
                    </h3>
                    {state.isMobile && <div className="value">
                        {formatAddress(curWhiteUser)}
                    </div>}
                    {!state.isMobile && <div className="value">
                        {curWhiteUser}
                    </div>}
                </Modal>
                <div className="panel-box">

                    <div className="panel-container">

                        {/* {isThreeAdmin && <div className="tip">
                                I can set up up to <strong>{maxFiveAdmin} </strong> activation accounts. I have set
                                up<strong> {activeArr.length} </strong>  activation accounts and can set up
                                another<strong> {maxFiveAdmin - activeArr.length} </strong>  activation accounts.
                            </div>} */}
                        <Modal className="model-dialogadd" title="Add" open={isAddMolOpen} onOk={handleSetActive}
                            onCancel={() => {
                                setAddOpen(false)
                            }}>
                            <Form form={form2} name="control-hooks" className="form">

                                {addWhiteArr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address" + index}
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                {/*<Input type="text" value={addressValue} onChange={handleInputChange}*/}
                                                {/*       onPaste={handlePaste}/>*/}
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">

                                    <img src={add} onClick={() => {
                                        addOneWhite()
                                    }} />
                                    <img src={cut} onClick={() => {
                                        removeOneWhite()
                                    }} />
                                </div>
                            </Form>
                        </Modal>

                        <div className="panel-title">
                            Set Super Accounts
                        </div>
                        <div className="superdao-list-box ">
                            <div className='listheadert'>

                                <div className=" list-header">
                                    <div className="col">
                                        {t("No.")}
                                    </div>
                                    {/*<div className="col">*/}
                                    {/*    ID*/}
                                    {/*</div>*/}

                                    <div className="col ">
                                        {t("Address")}
                                    </div>
                                    {/*<div className="col">*/}
                                    {/*    Delete*/}
                                    {/*</div>*/}

                                </div>

                                {
                                    activeArr.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                                        activeArr.map((item, index) => (
                                            <div className="list-item "
                                                key={index}
                                            >
                                                <div className="col no">
                                                    {index + 1}
                                                </div>
                                                {/*<div className="col id">*/}

                                                {/*</div >*/}

                                                {state.isMobile &&
                                                    <div className="col address">

                                                        <a>
                                                            {formatAddress(item)}
                                                        </a>
                                                    </div>
                                                }
                                                {!state.isMobile && <div className="col address" >
                                                    <a>{item}</a>
                                                </div>}

                                                {/*<div className="col sc1" >*/}
                                                {/*    <img src={sc} className="sc" id='scc' onClick={() => {*/}

                                                {/*        setDelOpen(true)*/}
                                                {/*    }} />*/}
                                                {/*</div>*/}

                                            </div>
                                        )
                                        )
                                }
                                <div className='addsbt' onClick={() => {
                                    setAddOpen(true)
                                }}>Add
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>


        </OgSetActiveStyle>
    )
}
export default AddThreeWhiteList
