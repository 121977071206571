import React, { useEffect, useState } from "react";
import { useConnect } from "../../../../api/contracts";

import { dealMethod, dealPayMethod, viewMethod } from "../../../../utils/contractUtil";
import { getContractByName, getContractByContract } from "../../../../api/connectContract";

import judgeStatus from "../../../../utils/judgeStatus";
import { Button, Form, Input, Select, Pagination, Empty, message, notification } from "antd";



import LockMiningAdminStyle from './NFTLockStyle'
import NFTLockV1 from "./components/NFTLockV1";
import NFTLockV2 from "./components/NFTLockV2";
import {useTranslation} from "react-i18next";

const LockMiningAdmin = () => {
    const [form] = Form.useForm();
    const [activeNav, setActiveNav] = useState(0);
    let { state, dispatch } = useConnect();
    const { t } = useTranslation();

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };


    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }


    }, [state.account])

    return (
        <LockMiningAdminStyle>
            <div className="nav-list">
                <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={()=>{
                    setActiveNav(0)
                }}>
                    {t("Node V1 Mining")}
                </div>
                <div className={"nav-item " + (activeNav == 1 ? "active" : "")}  onClick={()=>{
                    setActiveNav(1)
                }}>
                    {t("Node V2 Mining")}
                </div>

            </div>
            {
                activeNav==0&& <NFTLockV1></NFTLockV1>
            }
            {
                activeNav==1&& <NFTLockV2></NFTLockV2>
            }


        </LockMiningAdminStyle>
    )
}
export default LockMiningAdmin