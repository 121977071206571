
import React from "react";

function getItem(key,label,type) {
    return {
        key,
        label,
        
        type,
    };
}
export default  [
    getItem('SBDPurchase', 'Funding',<hr/>, ),
    getItem('Token', 'Token',<hr/>, ),
    getItem('NFT', 'NFT',<hr/>, ),
    getItem('Lock Mining', 'Lock Mining',<hr/>, ),
    getItem('SwapView', 'SwapView',<hr/>, ),

]