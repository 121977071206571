import {useConnect, connect} from "../../api/contracts";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import React, {useEffect, useState} from "react";
import {Button, notification, Select} from 'antd';
import {useTranslation} from 'react-i18next';

import {useLocation, useNavigate, Routes, Route, Link, NavLink} from "react-router-dom";
import NavList from "../NavList/MNavList";
import logo from "../../imgs/logo.png"
import {Network} from "../../config/constants";
import SuperbitdaoHeaderStyle from "./SuperbitdaoHeaderStyle";
import develop from "../../env"
import judgeStatus from "../../utils/judgeStatus";
import fresh from "../../imgs/fresh.svg"
function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true;
    } else {
        return false;
    }
}

const SuperbitdaoHeader = () => {
    let {state, dispatch} = useConnect();
    const history = useNavigate();
    const [chain, setChain] = useState("Arbitrum Goerli")
    const [lng, setLNG] = useState("zh")
    const { t } = useTranslation();

    const {i18n} = useTranslation();

    const goPage = (url) => {
        history(url);
    }
    const location = useLocation()
    const handleChange = () => {
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [

                Network[develop.chainId16]
            ],
        });

    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        // getUserFIDAndAddr()

    }, [state.account, state.networkId]);
    useEffect(() => {
        dispatch({type: "SET_ISMOBILE", payload: isMobile()})
    }, [])
    return (
        <SuperbitdaoHeaderStyle>
            <img className={"fresh"} src={fresh} alt="" onClick={()=>{window.location.reload()}}/>
            {state.isShowNav && <div className="m-nav">
                <div className="m-nav-content">
                    <div className="close" onClick={() => {
                        dispatch({type: "SET_IsShowNav", payload: false})
                    }}>
                        <svg t="1675219743493" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="2668" width="20" height="20">
                            <path
                                d="M925.468404 822.294069 622.19831 512.00614l303.311027-310.331931c34.682917-27.842115 38.299281-75.80243 8.121981-107.216907-30.135344-31.369452-82.733283-34.259268-117.408013-6.463202L512.000512 399.25724 207.776695 87.993077c-34.675754-27.796066-87.272669-24.90625-117.408013 6.463202-30.178323 31.414477-26.560936 79.375815 8.121981 107.216907l303.311027 310.331931L98.531596 822.294069c-34.724873 27.820626-38.341237 75.846432-8.117888 107.195418 30.135344 31.43699 82.72919 34.326806 117.408013 6.485715l304.178791-311.219137 304.177767 311.219137c34.678824 27.841092 87.271646 24.951275 117.408013-6.485715C963.808618 898.140501 960.146205 850.113671 925.468404 822.294069z"
                                fill="#ffffff" p-id="2669"></path>
                        </svg>
                    </div>
                    <NavList className="superdaonav" closeDialog={() => {
                        dispatch({type: "SET_IsShowNav", payload: false})
                    }}/>
                </div>
            </div>}

            <div className={"Superbitdao-header "+ ((location.pathname=="/LeaderboardBonus")?" bonus-bg":"")}>

                <div className="currentTitle">
                    <div className="logo-box">
                        <NavLink to='/'> <img width={120} className="logo" src={logo} alt=""/></NavLink>
                    </div>
                </div>
                <div className="nav-list">

                    <div className="nav-item">
                        <NavLink to='/SBDPurchase'><span>{t("Funding")}</span></NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to='/SBDLock'><span>{t("SBDLock")}</span></NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to='/NFTView'><span>NFT</span></NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to='/SwapView'><span>{t("Swap")}</span></NavLink>
                    </div>
                    <div className="nav-item">
                        <NavLink to='/LeaderboardBonus'><span>{t("Bonus")}</span></NavLink>
                    </div>
                </div>
                <Select
                    value={lng}
                    onChange={(value) => {
                        i18n.changeLanguage(value);
                        setLNG(value)
                    }}
                    className='select-box'
                    options={[{label: "English", value: "en"},
                        {label: "中文", value: "zh"}]}
                />
                <Button onClick={() => {
                    handleChange()
                }} className="passport-icon" type="primary">
                    <div style={{display: 'flex'}}>
                        <span className="bsc">BSC</span>
                        <div className={(state.networkId == develop.chainId16 ? "active " : " ") + 'cwallet'}></div>
                    </div>
                </Button>

                <ConnectWallet/>
                <svg onClick={() => {
                    dispatch({type: "SET_IsShowNav", payload: true})
                }} className="menu" t="1675153518613" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2667" width="32" height="32">
                    <path
                        d="M128 469.333333m40.533333 0l686.933334 0q40.533333 0 40.533333 40.533334l0 4.266666q0 40.533333-40.533333 40.533334l-686.933334 0q-40.533333 0-40.533333-40.533334l0-4.266666q0-40.533333 40.533333-40.533334Z"
                        p-id="2668" fill="#ffffff"></path>
                    <path
                        d="M128 682.666667m40.533333 0l686.933334 0q40.533333 0 40.533333 40.533333l0 4.266667q0 40.533333-40.533333 40.533333l-686.933334 0q-40.533333 0-40.533333-40.533333l0-4.266667q0-40.533333 40.533333-40.533333Z"
                        p-id="2669" fill="#ffffff"></path>
                    <path
                        d="M128 256m40.533333 0l686.933334 0q40.533333 0 40.533333 40.533333l0 4.266667q0 40.533333-40.533333 40.533333l-686.933334 0q-40.533333 0-40.533333-40.533333l0-4.266667q0-40.533333 40.533333-40.533333Z"
                        p-id="2670" fill="#ffffff"></path>
                </svg>
            </div>

        </SuperbitdaoHeaderStyle>
    )

}
export default SuperbitdaoHeader
