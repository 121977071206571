import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Switch} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import FireLockStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import addressMap from "../../../../api/addressMap";
import BigNumber from "bignumber.js";
import {MaxUint256, SFTCoinDecimal, SPTCoinDecimal} from "../../../../config/constants";
import add from "../../../../imgs/add.png";
import cut from "../../../../imgs/remove.png";
import {useTranslation} from "react-i18next";

const FireLock = (props) => {
    let {state, dispatch} = useConnect();


    const [monthArr, setMonthArr] = useState([{}])
    const [rateArr, setRateArr] = useState([{}])

    const [curNav, setCurNav] = useState(1)
    const [ownerAddr, setOwner] = useState("")
    const [curMonth, setCurMonth] = useState("0")

    const [parsed, setParsed] = useState()
    const [poolSFT, setPoolSFT] = useState()
    const [mySFT, setMySFT] = useState()
    const [selectOption, setSelectOption] = useState([])
    const [allProfit, setAllProfit] = useState()
    const { t } = useTranslation();


    const [form] = Form.useForm();

    const location = useLocation()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDSFTLock", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }


    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDSFTLock", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const removeOneWhite = async () => {
        let newmonthArr = JSON.parse(JSON.stringify(monthArr))
        newmonthArr.shift()
        setMonthArr(newmonthArr)
    }

    const addOneWhite = async () => {

        let newmonthArr = JSON.parse(JSON.stringify(monthArr))
        newmonthArr.push({})
        setMonthArr(newmonthArr)
    }


    const addOneRateWhite = async () => {
        let newrateArr = JSON.parse(JSON.stringify(rateArr))
        newrateArr.push({})
        setRateArr(newrateArr)
    }
    const removeOneRateWhite = async () => {
        let newrateArr = JSON.parse(JSON.stringify(rateArr))
        newrateArr.shift()
        setRateArr(newrateArr)
    }

    const getData = async (page) => {
        getOwner()
        getPause()
        getBalance()
        getAllProfit()
    }


    const getOwner = async () => {
        const ownerAddr = await handleViewMethod("owner", [])
        setOwner(ownerAddr)
    }
    const getBalance = async () => {
        let contractTemp = await getContractByName("SFT", state.api,)
        const balance = await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["SBDSFTLock"].address])
        setPoolSFT(BigNumber(balance).div(10**SFTCoinDecimal).toString())
        const mybalance = await viewMethod(contractTemp, state.account, "balanceOf", [state.account])
        setMySFT(BigNumber(mybalance).div(10**SFTCoinDecimal).toString())
    }
    const handleApproveCoin = async () => {
        let contractTemp = await getContractByContract("erc20",form.getFieldValue().coinAddr, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['SBDSFTLock'].address, MaxUint256])
    }
    const handleApprove = async () => {
        let contractTemp = await getContractByName("SFT", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['SBDSFTLock'].address, MaxUint256])
    }
    const handleAddRate= async () => {
        await handleDealMethod("changeRate", [[form.getFieldValue().month],[form.getFieldValue().rate]])
        getAllProfit()
    }
    const handleChangeRate= async () => {
        await handleDealMethod("changeRate", [[curMonth],[form.getFieldValue().rate]])
        getAllProfit()
    }
    const deposit = async () => {
        await handleDealMethod("deposit", [addressMap["SFT"].address,BigNumber(form.getFieldValue().dAmount).multipliedBy(10**SPTCoinDecimal).toFixed(0)])
        getBalance()
    }
    const withdraw = async () => {
        await handleDealMethod("adminWithdraw", [form.getFieldValue().coinAddr,BigNumber(form.getFieldValue().wAmount).multipliedBy(10**SPTCoinDecimal).toFixed(0)])
        getBalance()
    }

    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().owner])
        getOwner()
    }
    const handlePause = async () => {

        await handleDealMethod('pause', [])
        getPause()
    }
    const handleUnPause = async () => {
        await handleDealMethod('unpause', [])
        getPause()
    }
    const getPause = async () => {
        let res = await handleViewMethod('paused', [])
        setParsed(res)
    }

    const getAllProfit = async () => {
        let res = await handleViewMethod('getAllProfit', [])
        let selectOption = []
        if(res&&res.length>0){
            res.forEach(item=>{
                selectOption.push({
                    value:item,
                    label:item+"月"
                })
            })
        }
        setSelectOption(selectOption)
        setAllProfit(res)
    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()

    }, [state.account]);
    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    return (
        <FireLockStyle>

            <h1 className="title">
                SBDLock(SFT) Admin
            </h1>
            <div className="panel-box">
                <div className="panel-container">
                    <div className="nav-list">
                        <div className={"nav-item " + (curNav == 1 ? "active" : "")} onClick={() => {
                            setCurNav(1)
                        }}>
                            Owner
                        </div>

                    </div>
                    {curNav == 1 && <div className="part1">

                        <div className="content-item">
                            <h3>Owner Address</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {ownerAddr}
                                    </div>
                                </div>
                                <Form.Item
                                    name="owner"
                                    label="owner address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                transferOwnership()
                            }}>
                                 {t("Submit")}:
                            </Button>
                        </div>


                        <div className="content-item">
                            <h3> Contract Status({parsed ? "paused" : "not pause"})</h3>
                            <div className="panel-container">
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            handlePause()
                                        }}>
                                    Paused
                                </Button>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            handleUnPause()
                                        }}>
                                    Unpause
                                </Button>
                            </div>
                        </div>
                        <div className="lock-box" style={{marginTop:"50px"}}>
                            <h3> Deposit</h3>
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">SFT {t("Amounts")}</p>
                                        <p className="value">{t("My Balance")}: {mySFT}</p>
                                    </div>
                                    <Form.Item
                                        name="dAmount"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <Input
                                            placeholder="0"
                                          />
                                    </Form.Item>

                                    <div className="approve-box">
                                        <Button onClick={() => {
                                            handleApprove()
                                        }} type="primary" className="lock-btn">
                                            Approve
                                        </Button>
                                        <Button onClick={() => {
                                            deposit()
                                        }} type="primary" className="lock-btn">
                                             {t("Submit")}:
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="lock-box">
                            <h3> {t("Withdraw")}</h3>
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">Coin Address</p>
                                    </div>
                                    <Form.Item
                                        name="coinAddr"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <div className="input-box">
                                            <Input
                                                onChange={(e) => { }} />

                                        </div>
                                    </Form.Item>
                                    <div className="nft-balance">
                                        <p className="name">SFT {t("Amounts")}</p>
                                        <p className="value">Pool Balance: {poolSFT}</p>
                                    </div>
                                    <Form.Item
                                        name="wAmount"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <div className="input-box">
                                            <Input
                                                placeholder="0"
                                                onChange={(e) => { }} />

                                        </div>
                                    </Form.Item>


                                    <div className="approve-box">
                                        <Button onClick={() => {
                                            handleApproveCoin()
                                        }} type="primary" className="lock-btn">
                                            Approve
                                        </Button>
                                        <Button onClick={() => {
                                            withdraw()
                                        }} type="primary" className="lock-btn">
                                             {t("Submit")}:
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="content-item">
                            <Form form={form} name="control-hooks" className="form">
                                <h3 style={{marginTop:"30px"}}>Add Rate</h3>
                                <Form.Item
                                    name={"month" }
                                    validateTrigger="onBlur"
                                    label="Month"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name={"rate" }
                                    validateTrigger="onBlur"
                                    label="Rate"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>

                            </Form>
                            <Button onClick={() => {
                                handleAddRate()
                            }} type="primary" className="lock-btn">
                                 {t("Submit")}:
                            </Button>
                        </div>
                        <div className="content-item">
                            <Form form={form} name="control-hooks" className="form">
                                <h3 style={{marginTop:"30px"}}>Change Rate</h3>
                                <Select

                                    value={curMonth}
                                    onChange={(value) => {
                                        setCurMonth(value)
                                    }}
                                    className='select-box'
                                    options={selectOption}
                                    style={{

                                        width: "200px",
                                        color: '#FFF', marginLeft: "10px"
                                    }}

                                />
                                <Form.Item
                                    name={"rate" }
                                    validateTrigger="onBlur"
                                    label="Rate"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        {/*<Input type="text" value={addressValue} onChange={handleInputChange}*/}
                                        {/*       onPaste={handlePaste}/>*/}
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>

                            </Form>
                            <Button onClick={() => {
                                handleChangeRate()
                            }} type="primary" className="lock-btn">
                                 {t("Submit")}:
                            </Button>
                        </div>
                    </div>}


                </div>

            </div>
        </FireLockStyle>
    )
}
export default FireLock
