import {fetchQueryBase} from "./index";

// test05 new

let ogname = "patton-sr/oglockmainbsc"


export function getWithdrawRecords() {
    return fetchQueryBase(ogname, {
        text: `{
  withdrawRecords(first:1000,orderBy: blockTimestamp,orderDirection: desc){
    Contract_id
    user,
    amount
    blockTimestamp
  }
}`
    }, "")
}