import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import {
    message,
    Form,
    Pagination, Input, Modal
} from 'antd';

import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../utils/contractUtil"

import develop from "../../../env";
import {useNavigate} from "react-router-dom";
import judgeStatus from "../../../utils/judgeStatus";
import {dealTime} from "../../../utils/timeUtil";
import bigNodeIMG from "../../../imgs/BigNode.png"
import smallNodeIMG from "../../../imgs/SmallNode.png"
import superNodeIMG from "../../../imgs/SuperNode.png"
import NFTStyle from './NFTStyle';
import {getBigNftMintRecord, getSmallNftMintRecord, getSupNftMintRecord} from "../../../graph/NFTGQL";
import {formatAddress} from "../../../utils/publicJs";
import {act} from "react-dom/test-utils";
import addressMap from "../../../api/addressMap";
import {useTranslation} from "react-i18next";


const NFTView = (props) => {
    const curNode = props.curNode
    let {state, dispatch} = useConnect();
    const [activeNav, setActiveNav] = useState(1)


    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)

    const [smallInitAmount, setSmallInitAmount] = useState()
    const [bigInitAmount, setBigInitAmount] = useState()
    const [supInitAmount, setSupInitAmount] = useState()

    const [smallMinted, setSmallMinted] = useState()
    const [bigMinted, setBigMinted] = useState()
    const [supMinted, setSupMinted] = useState()

    const [smallNode, setSmallNode] = useState()
    const [bigNode, setBigNode] = useState()
    const [supNode, setSupNode] = useState()
    const [smallAdmin, setSmallAdmin] = useState("")
    const [bigAdmin, setBigAdmin] = useState("")
    const [supAdmin, setSupAdmin] = useState("")

    const [smallWhitelist, setSmallWhiteList] = useState([])
    const [bigWhitelist, setBigWhitelist] = useState([])
    const [supWhitelist, setSupWhitelist] = useState([])


    const [insmallWhitelist, setInSmallWhiteList] = useState(false)
    const [inbigWhitelist, setInBigWhitelist] = useState(false)
    const [insupWhitelist, setInSupWhitelist] = useState(false)

    const [isShowWhite, setIsShowWhite] = useState(false)
    const [curLevel, setCurLevel] = useState()

    const [smallRecord, setSmallRecord] = useState([])
    const [bigRecord, setBigRecord] = useState([])
    const [supRecord, setSupRecord] = useState([])
    const [totalNFT, setTotalNFT] = useState([])

    const history = useNavigate();
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const onChangePage = async (page) => {
        await setCurPage(page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleBigNodeDealMethod = async (name, params) => {
        let contractTemp = await getContractByContract("bignodeV1", bigNode, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const handleSmallNodeDealMethod = async (name, params) => {
        let contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const handleSupNodeDealMethod = async (name, params) => {

        let contractTemp = await getContractByContract("supnodeV1", supNode, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const mintNode = async () => {
        if (activeNav == 1 || activeNav == 2 || activeNav == 13) {
            // if (smallCreated) {
            //     message.error("You have minted it")
            // }
            handleSmallNodeDealMethod("mintForWhiteList", [])
        }
        if (activeNav == 3 || activeNav == 4 || activeNav == 14) {
            // if (bigCreated) {
            //     message.error("You have minted it")
            // }
            handleBigNodeDealMethod("mintForWhiteList", [])
        }
        if (activeNav == 5 || activeNav == 6 || activeNav == 15) {
            // if (supCreated) {
            //     message.error("You have minted it")
            // }
            handleSupNodeDealMethod("mintForWhiteList", [])
        }


        getInitAmount()
    }
    const getBalance = async () => {
        let totalNFT = []

        let contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
        for (let i = 0; i < smallMinted; i++) {
            try {
                let addr1 = await viewMethod(contractTemp, smallNode, "ownerOf", [i])
                if (addr1.toLowerCase() == state.account.toLowerCase()) {
                    totalNFT.push({
                        type: "small",
                        id: i
                    })
                }
            } catch (e) {
                console.log(e)
            }
        }

        let contractTemp2 = await getContractByContract("bignodeV1", bigNode, state.api,)
        for (let i = 0; i < bigMinted; i++) {
            try {
                let addr1 = await viewMethod(contractTemp2, smallNode, "ownerOf", [i])
                if (addr1.toLowerCase() == state.account.toLowerCase()) {
                    totalNFT.push({
                        type: "big",
                        id: i
                    })
                }
            } catch (e) {
                console.log(e)
            }
        }

        let contractTemp3 = await getContractByContract("supnodeV1", supNode, state.api,)
        for (let i = 0; i < supMinted; i++) {
            try {
                let addr1 = await viewMethod(contractTemp3, state.account, "ownerOf", [i])
                if (addr1.toLowerCase() == state.account.toLowerCase()) {
                    totalNFT.push({
                        type: "sup",
                        id: i
                    })
                }
            } catch (e) {
                console.log(e)
            }
        }

        setTotalNFT(totalNFT)
    }
    const getInitAmount = async () => {
        try {
            if (activeNav > 10) {
                let contractTemp = await getContractByContract("smallnodeV3", smallNode, state.api,)
                let smallMinted = await viewMethod(contractTemp, smallNode, "Minted", [])

                setSmallMinted(smallMinted)
                let contractTemp2 = await getContractByContract("bignodeV3", bigNode, state.api,)
                let bigMinted = await viewMethod(contractTemp2, bigNode, "Minted", [])
                setBigMinted(bigMinted)
                let contractTemp3 = await getContractByContract("supnodeV3", supNode, state.api,)
                let supMinted = await viewMethod(contractTemp3, supNode, "Minted", [])
                setSupMinted(supMinted)

                return
            }

            if (!smallNode) {
                return
            }
            if (!bigNode) {
                return
            }
            if (!supNode) {
                return
            }
            let contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
            const initAmount = await viewMethod(contractTemp, state.account, "initAmount", [])
            let smallMinted = await viewMethod(contractTemp, smallNode, "totalMint", [])

            setSmallInitAmount(initAmount)
            setSmallMinted(smallMinted)
            let contractTemp2 = await getContractByContract("bignodeV1", bigNode, state.api,)
            const initAmount2 = await viewMethod(contractTemp2, state.account, "initAmount", [])
            let bigMinted = await viewMethod(contractTemp2, bigNode, "totalMint", [])

            setBigMinted(bigMinted)
            setBigInitAmount(initAmount2)

            let contractTemp3 = await getContractByContract("supnodeV1", supNode, state.api,)
            const initAmount3 = await viewMethod(contractTemp3, state.account, "initAmount", [])
            let supMinted = await viewMethod(contractTemp3, supNode, "totalMint", [])

            setSupMinted(supMinted)
            setSupInitAmount(initAmount3)
        } catch (e) {
            console.log(e)
        }

    }


    const updateNodeAddr = () => {
        setSmallMinted(0)
        setBigMinted(0)
        setSupMinted(0)
        getInitAmount()
        if (activeNav < 10) {
            if (activeNav % 2 == 0) {
                setBigNode(addressMap["bignodeV2"].address)
                setSmallNode(addressMap["smallnodeV2"].address)
                setSupNode(addressMap["supnodeV2"].address)
            } else {
                setBigNode(addressMap["bignodeV1"].address)
                setSmallNode(addressMap["smallnodeV1"].address)
                setSupNode(addressMap["supnodeV1"].address)
            }
        } else {
            setBigNode(addressMap["bignodeV3"].address)
            setSmallNode(addressMap["smallnodeV3"].address)
            setSupNode(addressMap["supnodeV3"].address)
        }

    }
    useEffect(() => {
        updateNodeAddr()
    }, [activeNav])
    const getAdmin = async () => {
        if (!smallNode) {
            return
        }
        if (!bigNode) {
            return
        }
        if (!supNode) {
            return
        }
        let contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
        const Admin = await viewMethod(contractTemp, state.account, "owner", [])
        setSmallAdmin(Admin)

        let contractTemp2 = await getContractByContract("bignodeV1", bigNode, state.api,)
        const Admin2 = await viewMethod(contractTemp2, state.account, "owner", [])
        setBigAdmin(Admin2)

        let contractTemp3 = await getContractByContract("supnodeV1", supNode, state.api,)
        const Admin3 = await viewMethod(contractTemp3, state.account, "owner", [])
        setSupAdmin(Admin3)
    }
    const getWiteList = async () => {
        let contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
        const whitelist1 = await viewMethod(contractTemp, state.account, "getWiteList", [])
        setSmallWhiteList(whitelist1)

        const inW1 = whitelist1.some(item => {
            return item.toLowerCase() == state.account.toLowerCase()
        })
        setInSmallWhiteList(inW1)
        let contractTemp2 = await getContractByContract("bignodeV1", bigNode, state.api,)
        const whitelist2 = await viewMethod(contractTemp2, state.account, "getWiteList", [])
        setBigWhitelist(whitelist2)

        const inW2 = whitelist2.some(item => {
            return item.toLowerCase() == state.account.toLowerCase()
        })
        setInBigWhitelist(inW2)
        let contractTemp3 = await getContractByContract("supnodeV1", supNode, state.api,)
        const whitelist3 = await viewMethod(contractTemp3, state.account, "getWiteList", [])
        setSupWhitelist(whitelist3)

        const inW3 = whitelist3.some(item => {
            return item.toLowerCase() == state.account.toLowerCase()
        })
        setInSupWhitelist(inW3)
    }
    useEffect(async () => {
        const smallR = await getSmallNftMintRecord()
        const bigR = await getBigNftMintRecord()
        const supR = await getSupNftMintRecord()
        if (smallR && smallR.data) {
            setSmallRecord(smallR.data.records)
        }
        if (bigR && bigR.data) {
            setBigRecord(bigR.data.records)
        }
        if (supR && supR.data) {
            setSupRecord(supR.data.records)
        }

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }

        if (curNode == 1) {
            setBigNode(addressMap["bignodeV1"].address)
            setSmallNode(addressMap["smallnodeV1"].address)
            setSupNode(addressMap["supnodeV1"].address)
        }
        if (curNode == 2) {
            setBigNode(addressMap["bignodeV2"].address)
            setSmallNode(addressMap["smallnodeV2"].address)
            setSupNode(addressMap["supnodeV2"].address)
        }
        if (curNode == 3) {
            setBigNode(addressMap["bignodeV3"].address)
            setSmallNode(addressMap["smallnodeV3"].address)
            setSupNode(addressMap["supnodeV3"].address)
        }


    }, [state.account])
    const SelectOption1 = [
        {
            label: "Small  V1",
            value: 1
        },
        {
            label: "Big V1",
            value: 3
        },
        {
            label: "Super  V1",
            value: 5
        },
    ]
    const SelectOption2 = [
        {
            label: "Small V2",
            value: 2
        },
        {
            label: "Big   V2",
            value: 4
        },
        {
            label: " Super V2",
            value: 6
        },
    ]
    const SelectOption3 = [
        {
            label: "Small V3",
            value: 13
        },
        {
            label: "Big V3",
            value: 14
        },
        {
            label: "Super V3",
            value: 15
        },
    ]
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        if (smallNode && bigNode && supNode) {
            getAdmin()
            getWiteList()
            getInitAmount()
        }
    }, [smallNode, bigNode, supNode, state.account])
    useEffect(() => {
        if (smallMinted >= 0 && bigMinted >= 0 && supMinted >= 0) {
            // getBalance()
        }
    }, [smallMinted, bigMinted, supMinted])
    return (

        <NFTStyle>
            <Modal className="nft-dialog" title={t("Whitelist")} open={isShowWhite} footer={null} onCancel={() => {
                setIsShowWhite(false)
            }}>
                <div className='superdao-list-box up-list-box'>
                    <div className='list-header up-header'>
                        <div className='col'>
                            {t("No.")}
                        </div>
                        <div className='col'>
                            {t("Address")}
                        </div>
                    </div>
                    {(activeNav == 1 || activeNav == 2 || activeNav == 13) && smallWhitelist.map((item, index) => {
                        return (<div className='list-item up-item' key={index}>
                            <div className='col no'>
                                {index + 1}
                            </div>
                            <div className='col address'>
                                <a>
                                    {item}
                                </a>
                            </div>


                        </div>)
                    })}
                    {(activeNav == 3 || activeNav == 4 || activeNav == 14) && bigWhitelist.map((item, index) => {
                        return (<div className='list-item up-item' key={index}>
                            <div className='col no'>
                                {index + 1}
                            </div>
                            <div className='col address'>
                                <a>
                                    {item}
                                </a>
                            </div>


                        </div>)
                    })}
                    {(activeNav == 5 || activeNav == 6 || activeNav == 15) && supWhitelist.map((item, index) => {
                        return (<div className='list-item up-item' key={index}>
                            <div className='col no'>
                                {index + 1}
                            </div>
                            <div className='col address'>
                                <a>
                                    {item}
                                </a>
                            </div>

                        </div>)
                    })}
                </div>
                <div className="pagination">

                    <Pagination current={curPage} showSizeChanger
                                onShowSizeChange={handleShowSizeChange}
                                onChange={onChangePage} total={total}
                                defaultPageSize={pageCount}/>

                </div>
            </Modal>

            <div className="header-nav">

                <div className="nft-nav-list">
                    {curNode == 1 && <div className="nft-nav-list">
                        {SelectOption1.map((item) => (
                            <div className={"nav-item " + (activeNav == item.value ? "active" : "")} onClick={() => {
                                setActiveNav(item.value)
                            }}>{item.label}</div>
                        ))}
                    </div>}
                    {curNode == 2 && <div className="nft-nav-list">
                        {SelectOption2.map((item) => (
                            <div className={"nav-item " + (activeNav == item.value ? "active" : "")} onClick={() => {
                                setActiveNav(item.value)
                            }}>{item.label}</div>
                        ))}
                    </div>}
                    {curNode == 3 && <div className="nft-nav-list">
                        {SelectOption3.map((item) => (
                            <div className={"nav-item " + (activeNav == item.value ? "active" : "")} onClick={() => {
                                setActiveNav(item.value)
                            }}>{item.label}</div>
                        ))}
                    </div>}


                </div>
            </div>

            {(activeNav == 1 || activeNav == 2 || activeNav == 13) && (
                <div className="part1">
                    <div className="panel-box">
                        <div className="panel-container">
                            <div className='fun-container'>
                                <div className='fun-box'>
                                    <div className="message-box">
                                        <div className="in-line">
                                            <div className="left">
                                                {t("Contract Address")}
                                            </div>
                                            <div className="right">
                                                <a style={{color: "yellow"}}
                                                   href={develop.ethScan + "/address/" + smallNode}>
                                                    {formatAddress(smallNode)}
                                                </a>
                                            </div>
                                        </div>
                                        {activeNav < 10 && <div className="in-line">
                                            <div className="left">
                                                {t("Total Amounts")}
                                            </div>
                                            <div className="right">
                                                {smallInitAmount}
                                            </div>
                                        </div>}
                                        <div className="in-line">
                                            <div className="left">
                                                {t("Minted Amounts")}
                                            </div>
                                            <div className="right">
                                                {smallMinted}
                                            </div>
                                        </div>
                                        {activeNav < 10 && <div className="in-line">
                                            <div className="left">
                                                {t("Available")}
                                            </div>
                                            <div className="right">
                                                {smallInitAmount - smallMinted}
                                            </div>
                                        </div>}
                                    </div>

                                    <img src={smallNodeIMG}/>
                                    {insmallWhitelist && <div className='mint-btn'>
                                        <p onClick={() => {
                                            mintNode()
                                        }}>Mint</p>
                                    </div>}
                                    {!insmallWhitelist && <div className='mint-btn'>
                                        <p onClick={() => {

                                        }}>
                                            {t("Not In Whitelist")}
                                        </p>
                                    </div>}
                                    <div className='btn-box'>
                                        <div className='ant-btn' onClick={() => {
                                            setIsShowWhite(true)
                                            setCurLevel(1)
                                        }}>
                                            <p> {t("Whitelist")}</p>
                                        </div>
                                        {smallAdmin.toString().toLowerCase() == state.account.toLowerCase() &&
                                            <div className='ant-btn' onClick={() => {
                                                history(`/NFTAdminV${activeNav < 10 ? (activeNav % 2 == 0 ? 2 : 1) : 3}?level=1`)
                                            }}>
                                                <p>Admin</p>
                                            </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='panel-box'>
                        <div className='panel-container'>
                            <div className='panel-title'>
                                {t("Mint")}{t("Small")}{t("Node")} {activeNav < 10 ? (activeNav % 2 == 0 ? "V2" : "V1") : "V3"} {t("Records")}
                            </div>
                            <div className='superdao-list-box nft-list-box'>
                                <div className='list-header nft-header'>
                                    <div className='col'>
                                        {t("No.")}
                                    </div>
                                    <div className='col'>
                                        {t("Address")}
                                    </div>
                                    <div className='col'>
                                        {t("Time(UTC)")}
                                    </div>
                                </div>
                                {smallRecord.map((item, index) => {
                                    return (
                                        index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                        <div className='list-item nft-item' key={index}>
                                            <div className='col no'>
                                                {smallRecord.length - index}
                                            </div>
                                            <div className='col address'>
                                                <a target="_blank" href={develop.ethScan + "/address/" + item.addr}>
                                                    {formatAddress(item.addr)}
                                                </a>
                                            </div>

                                            <div className='col'>
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                })}

                            </div>
                            <div className="pagination">

                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>

                            </div>
                        </div>

                    </div>
                </div>
            )}
            {(activeNav == 3 || activeNav == 4 || activeNav == 14) && (
                <div className="part2">
                    <div className="panel-box">
                        <div className="panel-container">
                            <div className='fun-container'>
                                <div className='fun-box'>
                                    <div className="message-box">
                                        <div className="in-line">
                                            <div className="left">
                                                {t("Contract Address")}
                                            </div>
                                            <div className="right">
                                                <a style={{color: "yellow"}}
                                                   href={develop.ethScan + "/address/" + bigNode}>
                                                    {formatAddress(bigNode)}
                                                </a>
                                            </div>
                                        </div>
                                        {activeNav < 10 && <div className="in-line">
                                            <div className="left">
                                                {t("Total Amounts")}
                                            </div>
                                            <div className="right">
                                                {bigInitAmount}
                                            </div>
                                        </div>}
                                        <div className="in-line">
                                            <div className="left">
                                                {t("Minted Amounts")}
                                            </div>
                                            <div className="right">
                                                {bigMinted}
                                            </div>
                                        </div>
                                        {activeNav < 10 && <div className="in-line">
                                            <div className="left">
                                                {t("Available")}
                                            </div>
                                            <div className="right">
                                                {bigInitAmount - bigMinted}
                                            </div>
                                        </div>}
                                    </div>

                                    <img src={bigNodeIMG}/>

                                    {inbigWhitelist && <div className='mint-btn'>
                                        <p onClick={() => {
                                            mintNode()
                                        }}>Mint</p>
                                    </div>}
                                    {!inbigWhitelist && <div className='mint-btn'>
                                        <p onClick={() => {

                                        }}> {t("Not In Whitelist")}</p>
                                    </div>}

                                    <div className='btn-box'>
                                        <div className='ant-btn' onClick={() => {
                                            setIsShowWhite(true)
                                            setCurLevel(2)
                                        }}>
                                            <p> {t("Whitelist")}</p>
                                        </div>
                                        {bigAdmin.toString().toLowerCase() == state.account.toString().toLowerCase() &&
                                            <div className='ant-btn' onClick={() => {
                                                history(`/NFTAdminV${activeNav < 10 ? (activeNav % 2 == 0 ? 2 : 1) : 3}?level=2`)
                                            }}>
                                                <p>Admin</p>
                                            </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='panel-box'>
                        <div className='panel-container'>
                            <div className='panel-title'>
                                {t("Mint")}{t("Big")}{t("Node")} {activeNav < 10 ? (activeNav % 2 == 0 ? "V2" : "V1") : "V3"} {t("Records")}
                            </div>
                            <div className='superdao-list-box nft-list-box'>
                                <div className='list-header nft-header'>
                                    <div className='col'>
                                        {t("No.")}
                                    </div>
                                    <div className='col'>
                                        {t("Address")}
                                    </div>
                                    <div className='col'>
                                        {t("Time(UTC)")}
                                    </div>
                                </div>
                                {bigRecord.map((item, index) => {
                                    return (
                                        index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                        <div className='list-item nft-item' key={index}>
                                            <div className='col no'>
                                                {bigRecord.length - index}
                                            </div>
                                            <div className='col address'>
                                                <a target="_blank" href={develop.ethScan + "/address/" + item.addr}>
                                                    {formatAddress(item.addr)}
                                                </a>
                                            </div>

                                            <div className='col'>
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                })}

                            </div>
                            <div className="pagination">

                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>

                            </div>
                        </div>

                    </div>
                </div>
            )}
            {(activeNav == 5 || activeNav == 6 || activeNav == 15) && (
                <div className="part3">
                    <div className="panel-box">
                        <div className="panel-container">
                            <div className='fun-container'>
                                <div className='fun-box'>
                                    <div className="message-box">
                                        <div className="in-line">
                                            <div className="left">
                                                {t("Contract Address")}
                                            </div>
                                            <div className="right">
                                                <a style={{color: "yellow"}}
                                                   href={develop.ethScan + "/address/" + supNode}>
                                                    {formatAddress(supNode)}
                                                </a>
                                            </div>
                                        </div>
                                        {activeNav < 10 && <div className="in-line">
                                            <div className="left">
                                                {t("Total Amounts")}
                                            </div>
                                            <div className="right">
                                                {supInitAmount}
                                            </div>
                                        </div>}
                                        <div className="in-line">
                                            <div className="left">
                                                {t("Minted Amounts")}
                                            </div>
                                            <div className="right">
                                                {supMinted}
                                            </div>
                                        </div>
                                        {activeNav < 10 && <div className="in-line">
                                            <div className="left">
                                                {t("Available")}
                                            </div>
                                            <div className="right">
                                                {supInitAmount - supMinted}
                                            </div>
                                        </div>}
                                    </div>

                                    <img src={superNodeIMG}/>

                                    {insupWhitelist && <div className='mint-btn'>
                                        <p onClick={() => {
                                            mintNode()
                                        }}>Mint</p>
                                    </div>}
                                    {!insupWhitelist && <div className='mint-btn'>
                                        <p onClick={() => {

                                        }}> {t("Not In Whitelist")}</p>
                                    </div>}

                                    <div className='btn-box'>
                                        <div className='ant-btn' onClick={() => {
                                            setIsShowWhite(true)
                                            setCurLevel(3)
                                        }}>
                                            <p> {t("Whitelist")}</p>
                                        </div>
                                        {supAdmin.toString().toLowerCase() == state.account.toString().toLowerCase() &&
                                            <div className='ant-btn' onClick={() => {
                                                history(`/NFTAdminV${activeNav < 10 ? (activeNav % 2 == 0 ? 2 : 1) : 3}?level=3`)
                                            }}>
                                                <p>Admin</p>
                                            </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='panel-box'>
                        <div className='panel-container'>
                            <div className='panel-title'>
                                {t("Mint")}{t("Super")}{t("Node")} {activeNav < 10 ? (activeNav % 2 == 0 ? "V2" : "V1") : "V3"} {t("Records")}
                            </div>
                            <div className='superdao-list-box nft-list-box'>
                                <div className='list-header nft-header'>
                                    <div className='col'>
                                        {t("No.")}
                                    </div>
                                    <div className='col'>
                                        {t("Address")}
                                    </div>
                                    <div className='col'>
                                        {t("Time(UTC)")}
                                    </div>
                                </div>
                                {supRecord.map((item, index) => {
                                    return (
                                        index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                        <div className='list-item nft-item' key={index}>
                                            <div className='col no'>
                                                {supRecord.length - index}
                                            </div>
                                            <div className='col address'>
                                                <a target="_blank" href={develop.ethScan + "/address/" + item.addr}>
                                                    {formatAddress(item.addr)}
                                                </a>
                                            </div>

                                            <div className='col'>
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                })}
                            </div>
                            <div className="pagination">

                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>

                            </div>
                        </div>

                    </div>
                </div>
            )}
            {activeNav == 11 && (
                <div className="my-nft">
                    <div className="nft-list">
                        {totalNFT.map((item, index) => {
                            return (<div className="nft-item" key={index}>
                                {item.type == "small" && <img src={smallNodeIMG}/>}
                                {item.type == "big" && <img src={bigNodeIMG}/>}
                                {item.type == "sup" && <img src={superNodeIMG}/>}
                                <div className="id-box">
                                    <div className="left">ID</div>
                                    <div className="right">
                                        {item.id}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
            )}

        </NFTStyle>
    )
}
export default NFTView