import { fetchQueryBase } from "./index";

let pathname

if (true) {
    pathname = "wysten-hgg/lockv3"
}
export function getMiningRecord(account) {

    return fetchQueryBase(pathname, {
        text: `{
            locks(where:{user_contains:"${account}" }){
               node
                user
                power
                amount
                blockTimestamp
             }
        }`
    }, "")
}


export function getUnMiningRecord(account) {
 
    return fetchQueryBase(pathname, {
        text: `{
            withdraws(where:{user_contains:"${account}" } ){
         node
            user
            amount
            blockTimestamp
             }
        }`
    }, "")
}
export function getWithdrawSrtRecord(pageSize, page,account) {

    return fetchQueryBase(pathname, {
        text: `{
            bonuses(first:${pageSize} skip:${pageSize * (page - 1)} where:{user_contains:"${account}" } ){
         id
    user
    power
    amount
    blockTimestamp
                 }
        }`
    }, "")
}

