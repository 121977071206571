import React, { useEffect, useRef, useState } from 'react';
import { useConnect } from "../../../../api/contracts";
import BigNumber from "bignumber.js"

import {
    message,
    Form, Pagination,Empty
} from 'antd';
import { getContractByName, getContractByContract } from "../../../../api/connectContract";
import { dealMethod, dealPayMethod, viewMethod } from "../../../../utils/contractUtil"

import { useNavigate } from "react-router-dom";
import judgeStatus from "../../../../utils/judgeStatus";
import {getDonateRecord, getAllRegisters, getAllInvites, getAllAdminRate,} from "../../../../graph/purChaseGQL";
import OGUserAdminStyle from "./TeamIncomeStyle.js";
import SBDIcon from "../../../../imgs/SBD.png";
import USDTIcon from "../../../../imgs/usdt.png";
import ethereum from "../../../../imgs/ethereum.png";
import { ETHDecimals, SBDDecimals, TestDecimals, USDTDecimals } from "../../../../config/constants";
import { showNum } from "../../../../utils/bigNumberUtil";
import { formatAddress } from "../../../../utils/publicJs";
import { dealTime } from "../../../../utils/timeUtil";

import develop from "../../../../env";
import {useTranslation} from "react-i18next";

const OGPoolPublic = (props) => {
    let { state, dispatch } = useConnect();
    const [activeNav, setActiveNav] = useState(1)

    const [isSecondAdmin, setIsSecondAdmin] = useState(false)
    const [isThreeAdmin, setIsThreeAdmin] = useState(false)
    const [isFourAdmin, setIsFourAdmin] = useState(false)
    const [isFiveAdmin, setIsFiveAdmin] = useState(false)
    const [teamRecordArr, setTeamRecordArr] = useState([])
    const [totalUSDT, setTotalUSDT] = useState(0)
    const [rateRecordArr, setRateRecord] = useState([])
    const [rateAdminRecordArr, setAdminRateRecord] = useState([])

    const [total, setTotal] = useState(0)
    const [allRecords, setAllRecords] = useState([])
    const [curPage, setCurPage] = useState(1)
    const [pageCount, setPageCount] = useState(20)


    const history = useNavigate();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const handleUserViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("user", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handlePayDealMethod = async (name, params, value) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealPayMethod(contractTemp, state.account, name, params, value)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }


    const getIsAdmin = async () => {
        const isSecond = await handleViewMethod("checkAddrForAdminLevelTwo", [state.account])
        const isThree = await handleViewMethod("checkAddrForAdminLevelThree", [state.account])
        const isFour = await handleViewMethod("checkAddrForAdminLevelFour", [state.account])
        const isFive = await handleViewMethod("checkAddrForAdminLevelFive", [state.account])
        setIsSecondAdmin(isSecond)
        setIsThreeAdmin(isThree)
        setIsFourAdmin(isFour)
        setIsFiveAdmin(isFive)
    }
    const getMyLevel = () => {
        if (isSecondAdmin) {
            return 2
        }
        if (isThreeAdmin) {
            return 3
        }
        if (isFourAdmin) {
            return 4
        }
        if (isFiveAdmin) {
            return 5
        }
        return 1
    }

    const getData = async () => {
        try {
            let judgeRes = await judgeStatus(state)
            if (!judgeRes) {
                return
            }
            getIsAdmin()
        } catch (e) {
            console.log(e)
        }
    }
    const getRefArr = async (address, myTeamArr, level) => {
        let refRes = await getAllRegisters(address)
        if (refRes.data && refRes.data.allRegisters && refRes.data.allRegisters.length > 0) {
            let refArr = refRes.data.allRegisters
            const realRefArr = []
            refArr.forEach(item => {
                let hasAddress = false;
                realRefArr.forEach(refItem => {
                    if (refItem._user == item._user) {
                        hasAddress = true
                    }
                })
                if (!hasAddress) {
                    realRefArr.push(item)
                }
            })
            refArr = realRefArr
            if (refArr[0]._user != address) {
                refArr.forEach(item => {
                    item.level = level
                })
                myTeamArr.push(...refArr)
            }
            level++
            for (let i = 0; i < refArr.length; i++) {
                if (refArr[i]._user != address) {
                    await getRefArr(refArr[i]._user, myTeamArr, level)
                }
            }
        }
        return myTeamArr

    }
    const getAdminRate = async () => {
        const res = await getAllAdminRate()
        if (res && res.data) {
            setAdminRateRecord(res.data.allteams)
            setRateRecord(res.data.allteamrates)
        }

    }

    const getLevelInviteRate = async (address, recordTime) => {

        for (let i = 0; i < rateRecordArr.length; i++) {
            const  rateItem = rateRecordArr[i]
            const  item= rateAdminRecordArr[i]
            if ((item.addr == address) && (item.blockTimestamp == recordTime)) {

                if (item.admin1.toLowerCase() == state.account.toLowerCase()) {
                    return {
                        rate: rateItem.rate1,
                    }
                }
                if (item.admin2.toLowerCase() == state.account.toLowerCase()) {
                    return {
                        rate: rateItem.rate2,
                    }
                }
                if (item.admin3.toLowerCase() == state.account.toLowerCase()) {
                    return {
                        rate: rateItem.rate3,
                    }
                }
                if (item.admin4.toLowerCase() == state.account.toLowerCase()) {

                    return {
                        rate: rateItem.rate4,
                    }
                }
                if (item.admin5.toString().toLowerCase() == state.account.toString().toLowerCase()) {
                    return {
                        rate: rateItem.rate5,
                    }
                }
                if (item.admin6.toString().toLowerCase() == state.account.toString().toLowerCase()) {
                    return {
                        rate: rateItem.rate6,
                    }
                }
                if (item.admin7.toString().toLowerCase() == state.account.toString().toLowerCase()) {
                    return {
                        rate: rateItem.rate7,
                    }
                }
                if (item.admin8.toString().toLowerCase() == state.account.toString().toLowerCase()) {
                    return {
                        rate: rateItem.rate8,
                    }
                }
                if (item.admin9.toString().toLowerCase() == state.account.toString().toLowerCase()) {
                    return {
                        rate: rateItem.rate9,
                    }
                }
            }
        }
        return 0

    }

    const getRecord = async () => {
        try {
            let res = await getDonateRecord()

            if (res.data && res.data.allRecords) {
                const recordArr = res.data.allRecords
                const refArr = await getRefArr(state.account, [], 1)
                let teamRecordArr = [], totalUSDT = 0
                for (let i = 0; i < refArr.length; i++) {
                    const refItem = refArr[i]
                    for (let j = 0; j < recordArr.length; j++) {
                        const recordItem = recordArr[j]
                        if (recordItem.addr.toLowerCase() == refItem._user.toLowerCase()) {
                            const rate =   (await getLevelInviteRate(refItem._user, recordItem.blockTimestamp)).rate /100
                            recordItem.level = refItem.level
                            recordItem.rate = rate
                            teamRecordArr.push(recordItem)
                            totalUSDT = BigNumber(totalUSDT).plus(recordItem.usdtAmount / 10 ** USDTDecimals) * rate / 100
                        }
                    }
                }
                setTotalUSDT(totalUSDT.toFixed(6).toString())
                setTeamRecordArr(teamRecordArr)
            }


        } catch (e) {
            console.log(e)
        }
    }


    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getAdminRate()
    }, [state.account]);
    useEffect(() => {
        if (rateRecordArr.length > 0) {
            getData()
            getRecord()
        }
    }, [rateRecordArr, ])

    return (
        <OGUserAdminStyle>

            <div className="panel-box part2">
                <div className="panel-container" style={{ boxShadow: "none", padding: '5px 0%' }}>
                    <div className="list-top-part">

                    </div>
                    <div className="flex-box total-box">
                        <div className="reward-item">
                            <p>USDT Total Income</p>
                            <img width={20} src={USDTIcon} alt="" />
                            <span> {showNum(totalUSDT, 1)} USDT</span>
                        </div>

                    </div>
                    <div className="panel-title">
                        {t("Team Funding List")}
                    </div>
                    <div className="superdao-list-box donate-list" style={{ minWidth: '100%' }}>
                        <div className='listheadert'>
                            <div className="list-header ">
                                <div className="col">
                                    {t("No.")}
                                </div>
                                {/* <div className='col'>
                                    Level
                                </div>
                                <div className="col">
                                    Divide Percentage
                                </div> */}
                                {/*<div className='col'>*/}
                                {/*    ID*/}
                                {/*</div>*/}
                                <div className="col">
                                    {t("Address")}
                                </div>
                                <div className="col">
                                    Price
                                </div>
                                <div className="col">
                                    Cost
                                </div>
                                <div className="col">
                                    Receive SBD
                                </div>
                                <div className="col">
                                    {t("Lock SBD")}
                                </div>
                                <div className="col">
                                      {t("Receive SVT")}
                                </div>
                                <div className="col">
                                    Receive NFT
                                </div>
                                {/* <div className="col">
                                    Rewards
                                </div> */}
                                <div className="col">
                                     {t("Time(UTC)")}
                                </div>
                            </div>

                            {
                                  teamRecordArr.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                                teamRecordArr.map((item, index) => (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    <div className="list-item"
                                        key={index}
                                    >
                                        <div className="col no">
                                           {index + 1}
                                        </div>


                                        {/*<div className='col id'>*/}
                                        {/*    {item.id}*/}
                                        {/*</div>*/}
                                        <div className="col address">
                                            <a target="_blank"
                                                href={develop.ethScan + "/address/" + item.addr}> {formatAddress(item.addr)} </a>
                                        </div>
                                        <div className="col price">
                                            <img width={20} height={20} style={{ marginRight: "3px" }} src={USDTIcon}
                                                alt="" />
                                            {showNum(BigNumber(item.usdtAmount).dividedBy(BigNumber(item.sbdAmount).plus(item.lockSbd)), 4)}
                                        </div>
                                        <div className="col cost">
                                            <img width={20} height={20} style={{ marginRight: "3px" }} src={USDTIcon}
                                                alt="" />
                                            {showNum(item.usdtAmount / 10 ** USDTDecimals, 3)}
                                        </div>
                                        <div className="col receive">

                                            {showNum(item.sbdAmount/ 10 ** SBDDecimals , 3)}
                                        </div>
                                        <div className="col lock">

                                            {showNum(item.lockSbd/ 10 ** SBDDecimals , 3)}
                                        </div>

                                        <div className="col receive-svt">

                                        </div>

                                        <div className="col receive-nft">
                                            {formatAddress(item.receiveNft)}
                                        </div>
                                        <div className="col">
                                            {dealTime(item.blockTimestamp)}
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className="pagination">
                        {
                            <Pagination current={curPage} showSizeChanger
                                onShowSizeChange={handleShowSizeChange}
                                onChange={onChangePage} total={total}
                                defaultPageSize={pageCount} />
                        }
                    </div>
                </div>
            </div>


        </OGUserAdminStyle>
    )
}
export default OGPoolPublic
